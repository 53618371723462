import {
  Box,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { useCallback } from 'react';

type CheckboxProps = {
  value: boolean;
  onChange: (checked: boolean) => void;
  label: string;
} & Omit<MuiCheckboxProps, 'value' | 'onChange'>;

export const Checkbox = ({
  value,
  onChange,
  label,
  ...props
}: CheckboxProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Box>
      <FormControlLabel
        sx={{ mr: 0, userSelect: 'none' }}
        control={
          <MuiCheckbox checked={value} onChange={handleChange} {...props} />
        }
        label={label}
      />
    </Box>
  );
};

type CheckboxNativeProps = {
  value: boolean;
  onChange: MuiCheckboxProps['onChange'];
  label: string;
} & Omit<MuiCheckboxProps, 'value' | 'onChange'>;

export const CheckboxNative = ({
  value,
  onChange,
  label,
  ...props
}: CheckboxNativeProps) => {
  return (
    <Box>
      <FormControlLabel
        sx={{ mr: 0 }}
        control={<MuiCheckbox checked={value} onChange={onChange} {...props} />}
        label={label}
      />
    </Box>
  );
};
