import { proxy, subscribe } from 'valtio';
import { useProxy } from 'valtio/utils';

type NumberOrStringArray = (number | string)[];

export type SettingsStoreType = {
  favorite: {
    music: NumberOrStringArray;
    sound: NumberOrStringArray;
    background: NumberOrStringArray;
    character: NumberOrStringArray;
    popup: NumberOrStringArray;
    evidence: NumberOrStringArray;
    dialogueBox: NumberOrStringArray;
  };
  audio: {
    volume: {
      master: number;
      music: number;
      sound: number;
      blip: number;
    };
    muted: {
      master: boolean;
      music: boolean;
      sound: boolean;
      blip: boolean;
    };
  };
  enableBackups: boolean;
};

export const settingsStore = proxy<SettingsStoreType>({
  favorite: {
    music: [],
    sound: [],
    background: [],
    character: [],
    popup: [],
    evidence: [],
    dialogueBox: [],
  },
  audio: {
    volume: {
      master: 100,
      music: 75,
      sound: 75,
      blip: 22,
    },
    muted: {
      master: false,
      music: false,
      sound: false,
      blip: false,
    },
  },
  enableBackups: true,
});

subscribe(settingsStore, () => {
  localStorage.setItem('settings', JSON.stringify(settingsStore));
});

window.addEventListener('storage', (event) => {
  if (event.key === 'settings' && event.newValue) {
    Object.assign(settingsStore, JSON.parse(event.newValue));
  }
});

export const useSettingsStore = () => useProxy(settingsStore);
