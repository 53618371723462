import { useEffect, useState } from 'react';
import { shakeBubble } from './hooks/usePlayerStyles';
import { usePlayer } from './providers/PlayerProvider';
import { SpeechBubble } from './ui/SpeechBubble';

export const PlayerSpeechBubble = () => {
  const {
    timeline: { addEvent },
    actions: { nextStep },
    step,
    audio: { playSound },
    frame: { speechBubble },
  } = usePlayer();

  const [show, setShow] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (step !== 'speech_bubble') {
      setShow(false);

      return;
    }

    if (!speechBubble) {
      setShow(false);

      nextStep();

      return;
    }

    setShow(true);

    setAnimationKey((prev) => prev + 1);

    addEvent(() => {
      nextStep();
    }, speechBubble?.duration || 950);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!show || !speechBubble) {
      return;
    }

    playSound(
      speechBubble.soundUrl || '/Audio/Vocal/sfx-objection.wav',
      100,
      100,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, speechBubble, animationKey]);

  if (!speechBubble || !show) {
    return null;
  }

  return (
    <SpeechBubbleWithRefresh
      key={animationKey}
      url={speechBubble.imageUrl}
      sx={speechBubble.shake ? { ...shakeBubble } : {}}
      fullscreen={speechBubble.fullscreen}
    />
  );
};

// in combination with key prop, this will force the component to re-render and refresh the image animation
const SpeechBubbleWithRefresh = ({
  url,
  sx,
  ...rest
}: Parameters<typeof SpeechBubble>[0]) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    setImageUrl(
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    );

    requestAnimationFrame(() => {
      setImageUrl(url ?? undefined);
    });
  }, [url]);

  // prevents the shake animation from being jumpy
  const sxProp =
    imageUrl ===
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      ? undefined
      : sx;

  if (!imageUrl) {
    return null;
  }

  return <SpeechBubble {...rest} url={imageUrl} sx={sxProp} />;
};
