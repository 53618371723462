import { useCallback, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { FlashType, ShakeType } from '../types';
import { getAspectRatioNumber } from '../utils/utils';
import { usePlayerSize } from './usePlayerSize';
import { useTimeline } from './useTimeline';

type ShakeEffect = {
  id: string;
  shake: ShakeType;
};

type PlayerUI = {
  shakes: ShakeEffect[];
  flash?: FlashType;
};

const initialState = () =>
  ({
    shakes: [],
  }) satisfies PlayerUI;

export const usePlayerUi = (
  playerContainerRef: React.RefObject<HTMLDivElement>,
  aspectRatio: string | undefined,
  width: number | undefined,
  addEvent: ReturnType<typeof useTimeline>['addEvent'],
) => {
  const state = useRef(proxy<PlayerUI>(initialState())).current;

  const snapshot = useSnapshot(state);

  const {
    state: playerSizeState,
    actions: { setPlayerSize, reset: resetPlayerSize },
  } = usePlayerSize(
    playerContainerRef,
    getAspectRatioNumber(aspectRatio || '3:2'),
    width,
  );

  const addShake = useCallback(
    (shake: ShakeType, duration: number) => {
      const id = uuidv4();
      const newShake = { id, shake };

      state.shakes.push(newShake);

      addEvent(
        () => {
          state.shakes = state.shakes.filter((s) => s.id !== id);
        },
        duration,
        false,
      );
    },
    [addEvent, state],
  );

  const setFlash = useCallback(
    (flash?: FlashType) => {
      state.flash = flash;
    },
    [state],
  );

  const init = useCallback(() => {
    setPlayerSize();
  }, [setPlayerSize]);

  const reset = useCallback(() => {
    Object.assign(state, initialState());

    resetPlayerSize();
  }, [resetPlayerSize, state]);

  const fullState = useMemo(
    () => ({ ...snapshot, ...playerSizeState }),
    [playerSizeState, snapshot],
  );

  const actions = useMemo(
    () => ({
      init,
      reset,
      addShake,
      setFlash,
    }),
    [addShake, init, reset, setFlash],
  );

  return { state: fullState, actions };
};
