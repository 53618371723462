import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import { Character_Alignment, Character_Side } from '@shared/types';
import {
  CharacterDto,
  CreateCharacterDto,
  UpdateCharacterDto,
} from '@web/api/api';
import { BackgroundInput } from '@web/components/common/form/BackgroundInput';
import { Background } from '@web/components/player/ui/Background';
import { Container } from '@web/components/player/ui/Container';
import { Desk } from '@web/components/player/ui/Desk';
import { assetActions } from '@web/store/assets/actions';
import { useAssetStore } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { getErrorMessage } from '@web/utils/error';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';
import Form from '../../common/form/Form';
import { DualGridPicker } from '../../maker/ui/DualGridPicker';
import { assetDialogState } from '../dialogs/AssetsDialogState';
import { AdvancedCharacterForm } from './AdvancedCharacterForm';
import { LocationInput } from './LocationInput';
import { SpeechBlipInput } from './SpeechBlipInput';

type Props = {
  onAdded?: (data: CharacterDto) => void;
};

export const CharacterForm = ({ onAdded }: Props) => {
  const { editingAssetId } = useSnapshot(assetDialogState);

  return !editingAssetId ? (
    <SimpleCharacterForm onAdded={onAdded} />
  ) : (
    <AdvancedCharacterForm />
  );
};

const SimpleCharacterForm = memo(({ onAdded }: Props) => {
  const schema = yup.object().shape({
    id: yup.number().optional(),
    name: yup.string().max(80).required(),
    nameplate: yup.string().max(40).required(),
    side: yup.string().required().oneOf(Object.values(Character_Side)),
    alignment: yup
      .number()
      .required()
      .oneOf(Object.values(Character_Alignment) as number[]),
    backgroundId: yup.number().required(),
    blipUrl: urlValidation().required(),
  });

  const onSubmit = async (data: CreateCharacterDto | UpdateCharacterDto) => {
    try {
      if (assetDialogState.editingAssetId) {
        await assetActions.updateAsset(
          'character',
          assetDialogState.editingAssetId,
          data,
        );
      } else {
        const asset = await assetActions.addAsset('character', data);

        onAdded?.(asset);
      }

      assetDialogState.editingAsset = undefined;
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }
  };

  const defaultValues = useMemo(
    () =>
      ({
        alignment: Character_Alignment.Default,
      }) satisfies Partial<CreateCharacterDto>,
    [],
  );

  const handleCancel = () => {
    assetDialogState.editingAsset = undefined;
  };

  return (
    <Form
      schema={schema}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      mode="onChange"
    >
      {({ control, register, errors, loading }) => (
        <Stack spacing={2}>
          <Typography variant="h6">
            {assetDialogState.editingAssetId ? 'Edit' : 'Add'} Character
          </Typography>

          <DualGridPicker>
            <Stack spacing={2}>
              {errors.root?.message && (
                <Alert severity="error" variant="filled">
                  {errors.root.message}
                </Alert>
              )}

              <TextField
                label="Name"
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
                autoFocus
                fullWidth
              />

              <TextField
                label="Name Plate"
                {...register('nameplate')}
                error={!!errors.nameplate}
                helperText={errors.nameplate?.message}
                fullWidth
              />

              <LocationInput control={control} />

              <Controller
                control={control}
                name="backgroundId"
                render={({ field }) => (
                  <BackgroundInput
                    label="Default Background"
                    allowWide={false}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.backgroundId?.message}
                  />
                )}
              />

              <SpeechBlipInput control={control} />
            </Stack>

            <CharacterPreview control={control} />
          </DualGridPicker>

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {assetDialogState.editingAssetId ? 'Edit' : 'Add'}
            </Button>

            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
});

const CharacterPreview = ({
  control,
}: {
  control: Control<CreateCharacterDto | UpdateCharacterDto> | undefined;
}) => {
  const { aspectRatio } = useMakerStore();

  const backgroundId = useWatch({ control, name: 'backgroundId' });

  const {
    background: { cache },
  } = useAssetStore();

  const bg = cache[backgroundId || 0];

  if (!bg) return null;

  return (
    <Container aspectRatio={aspectRatio}>
      <Background url={bg.url} isWide={bg.isWide} />
      <Desk url={bg.deskUrl} isWide={bg.isWide} />
    </Container>
  );
};
