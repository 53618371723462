import { Alert, Box, Button, Stack, TextField } from '@mui/material';
import { UpdateSpeechBubbleDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { ComboBox } from '@web/components/common/form/ComboBox';
import { setFormErrors } from '@web/components/common/form/Form';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { getErrorMessage, ValidationError } from '@web/utils/error';
import { Controller, useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import { SpeechBubbleUpdateWatcher } from '../CharacterUpdateWatchers';
import { SpeechBubbleDeleteButton } from '../pose/PoseDeleteButtons';
import { updateCharacterSpeechBubbles } from '../pose/utils';
import {
  useCharacterEditor,
  useCharacterExtraEditor,
} from '../providers/CharacterEditorProvider';

export const MainSpeechBubbleEditor = memo(({ index }: { index: number }) => {
  const state = useCharacterExtraEditor();
  const characterState = useCharacterEditor();

  const { character } = useSnapshot(characterState);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().max(80).required(),
        duration: yup.number().required().min(0),
        imageUrl: urlValidation().required('Image URL is required'),
        soundUrl: urlValidation().defined(),
        shake: yup.boolean().defined(),
        fullscreen: yup.boolean().defined(),
        order: yup.number().defined(),
      }),
    [],
  );

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<UpdateSpeechBubbleDto>({
    defaultValues: character.speechBubbles[index],
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: UpdateSpeechBubbleDto) => {
    try {
      const speechBubble = characterState.character.speechBubbles[index];
      const result =
        speechBubble.id < 0
          ? await ApiClient.assets.characterSpeechBubble.create({
              ...data,
              characterId: characterState.characterId,
            })
          : await ApiClient.assets.characterSpeechBubble.update(
              speechBubble.id,
              data,
            );

      characterState.character.speechBubbles[index] = result.data;

      updateCharacterSpeechBubbles(
        characterState.characterId,
        characterState.character.speechBubbles,
      );

      enqueueSnackbar('Speech bubble saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        setFormErrors(err, setError);
      } else {
        setError('root', { message: getErrorMessage(err) });
      }
    }
  };

  const onPreview = (data: UpdateSpeechBubbleDto) => {
    state.setPreviewIndex(index);
  };

  const handleCancel = (index: number) => {
    characterState.character.speechBubbles =
      characterState.character.speechBubbles.filter((_, i) => i !== index);
  };

  return (
    <Box component="form">
      <Stack spacing={2}>
        {errors.root?.message && (
          <Alert severity="error" variant="filled">
            {errors.root.message}
          </Alert>
        )}

        <TextField
          label="Name"
          {...register('name')}
          variant="standard"
          size="small"
          error={!!errors.name}
          helperText={errors.name?.message}
          fullWidth
        />

        <Controller
          control={control}
          name="imageUrl"
          render={({ field, fieldState }) => (
            <ComboBox
              label="Image URL"
              value={field.value}
              onChange={field.onChange}
              options={presetSpeechBubbles}
              variant="standard"
              error={fieldState.error?.message}
            />
          )}
        />

        <TextField
          label="Sound URL"
          {...register('soundUrl')}
          variant="standard"
          size="small"
          error={!!errors.soundUrl}
          helperText={errors.soundUrl?.message}
          fullWidth
        />

        <NumberTextField
          label="Duration"
          {...register('duration')}
          variant="standard"
          size="small"
          error={!!errors.duration}
          helperText={errors.duration?.message}
          fullWidth
        />

        <Stack direction="row" spacing={2}>
          <Controller
            control={control}
            name="shake"
            render={({ field }) => (
              <Checkbox
                label="Shake effect"
                value={!!field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="fullscreen"
            render={({ field }) => (
              <Checkbox
                label="Fullscreen"
                value={!!field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          py={2}
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Save
            </Button>

            {character.speechBubbles[index].id > 0 && (
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit(onPreview)}
              >
                Preview
              </Button>
            )}
          </Stack>

          {character.speechBubbles[index].id > 0 && (
            <SpeechBubbleDeleteButton id={character.speechBubbles[index].id} />
          )}

          {character.speechBubbles[index].id < 0 && (
            <Button variant="outlined" onClick={() => handleCancel(index)}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>

      <SpeechBubbleUpdateWatcher control={control} index={index} />
    </Box>
  );
});

const presetSpeechBubbles = [
  { id: '/Images/Bubbles/1.png', name: 'Objection!' },
  { id: '/Images/Bubbles/2.png', name: 'Hold It!' },
  { id: '/Images/Bubbles/3.png', name: 'Take That!' },
  { id: '/Images/Bubbles/4.png', name: 'Gotcha!' },
  { id: '/Images/Bubbles/5.png', name: 'Eureka!' },
];
