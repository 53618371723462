import { CaseFrame } from '@shared/types';
import { CharacterDto } from '@web/api/api';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';

export const CharacterTabMobile = () => {
  return (
    <AssetTabMobile type="character" PreviewComponent={PreviewCharacter} />
  );
};

const PreviewCharacter = memo(({ value }: { value: CharacterDto | null }) => {
  if (value === null) {
    return null;
  }

  const frame: CaseFrame = {
    id: -1,
    text: '',
    characterId: value.id,
    poseId: value.poses?.[0]?.id,
  };

  return <Thumbnail frame={frame} serverThumbnails={false} />;
});
