import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { getNextButtonType } from '@web/components/player/dialogue/NextButton';
import { NextButtonIcons } from '@web/components/player/dialogue/NextIcons';
import { useMemo } from 'react';

type NextButtonIconInputProps = {
  type: ReturnType<typeof getNextButtonType>;
  value: string;
  onChange: (value: string) => void;
  variant?: TextFieldVariants;
  size?: 'small' | 'medium';
  error?: string;
};

export const NextButtonIconInput = ({
  type,
  value,
  onChange,
  variant,
  size,
  error,
}: NextButtonIconInputProps) => {
  const iconOptions = useMemo(() => Object.keys(NextButtonIcons), []);

  if (type === 'text')
    return (
      <TextField
        label="Next Button Text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant={variant || 'standard'}
        size={size || 'medium'}
        error={!!error}
        helperText={error}
        fullWidth
      />
    );

  if (type === 'icon' && iconOptions.length > 0) {
    return (
      <Autocomplete
        options={iconOptions}
        value={value}
        onChange={(_, newValue) => onChange(newValue || iconOptions[0])}
        renderInput={(params) => (
          <RenderAutocompleteInput
            label="Icon"
            params={params}
            variant={variant || 'standard'}
            search={false}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {NextButtonIcons[value]({})}
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {NextButtonIcons[option]({})}
          </Box>
        )}
        size={size || 'medium'}
        disableClearable
      />
    );
  }

  return null;
};
