import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle, ExpandMore, RemoveRedEye } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Stack,
} from '@mui/material';
import { CaseFrame } from '@shared/types';
import { PoseDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { FramePreviewer } from '@web/components/common/FramePreviewer';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { PlayerAssetsProvider } from '@web/components/player/providers/PlayerAssetsProvider';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { assetActions } from '@web/store/assets/actions';
import { debounce } from 'lodash';
import {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { proxy, ref, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { assetDialogState } from '../../dialogs/AssetsDialogState';
import { useCharacterFormTab } from '../AdvancedCharacterFormState';
import {
  CharacterExtraEditorContextValue,
  CharacterExtraEditorProvider,
  useCharacterEditor,
  useCharacterExtraEditor,
} from '../providers/CharacterEditorProvider';
import { AnimationPoseEditor } from './PoseAnimationsEditor';
import { EffectPoseEditor } from './PoseEffectsEditor';
import { MainPoseEditor } from './PoseMainEditor';
import { AudioPoseEditor } from './PoseSoundsEditor';

export const PosesEditor = () => {
  const { scrollRef } = useSnapshot(assetDialogState);

  const state = useRef(
    proxy<CharacterExtraEditorContextValue>({
      expanded: false,
      previewIndex: undefined,
      scrollTop: ref({ value: 0 }),
      setPreviewIndex: (index: number | undefined) => {
        if (index !== undefined) {
          state.scrollTop.value = scrollRef.current?.scrollTop || 0;
        } else {
          setTimeout(() => {
            scrollRef.current?.scrollTo({
              top: state.scrollTop.value,
              behavior: 'instant',
            });
          }, 0);
        }
        state.previewIndex = index;
      },
    }),
  ).current;

  const characterState = useCharacterEditor();

  const { character } = useProxy(characterState);
  const { previewIndex } = useProxy(state);

  const handleAddPose = () => {
    characterState.character.poses.push({
      id: -Math.random() * 1000 - 1,
      name: `Pose ${character.poses.length + 1}`,
      idleImageUrl: '',
      speakImageUrl: '',
      iconUrl: '',
      isSpeedlines: false,
      order: character.poses.length,
      poseStates: [],
      poseAudioTicks: [],
      poseFunctionTicks: [],
    });

    state.expanded = characterState.character.poses.length - 1;
  };

  const debounceReorderPoses = useRef(
    debounce((characterId: number, poses: PoseDto[]) => {
      const savedItems = poses.filter((f) => f.id > 0);

      if (savedItems.length === 0) return;

      reorderPoses(characterId, savedItems);
    }, 800),
  ).current;

  const handleDragEnd = (
    sourceId: UniqueIdentifier,
    destinationId?: UniqueIdentifier | undefined,
  ) => {
    const oldIndex = character.poses.findIndex((i) => i.id === sourceId);
    const newIndex = character.poses.findIndex((i) => i.id === destinationId);

    characterState.character.poses = arrayMove(
      characterState.character.poses,
      oldIndex,
      newIndex,
    );

    characterState.character.poses = characterState.character.poses.map(
      (p, i) => ({
        ...p,
        order: i,
      }),
    );

    debounceReorderPoses(
      characterState.characterId,
      characterState.character.poses,
    );
  };

  return (
    <Stack spacing={2}>
      <CharacterExtraEditorProvider value={state}>
        <MultiHideView index={previewIndex === undefined ? 0 : 1}>
          <>
            <Button
              variant="contained"
              color="info"
              onClick={handleAddPose}
              disabled={character.poses.length >= 50}
              fullWidth
            >
              Add Pose
            </Button>

            <SortableDndProvider
              items={character.poses}
              onDragEnd={handleDragEnd}
            >
              {character.poses.map((pose, index) => (
                <DraggablePose key={pose.id} index={index} />
              ))}
            </SortableDndProvider>
          </>

          <Previewer />
        </MultiHideView>
      </CharacterExtraEditorProvider>
    </Stack>
  );
};

const DraggablePose = memo(({ index }: { index: number }) => {
  const state = useCharacterExtraEditor();
  const characterState = useCharacterEditor();

  const { character } = useProxy(characterState);
  const { expanded } = useSnapshot(state);

  const pose = character.poses[index];

  const handleExpand = (index: number) => {
    state.expanded = state.expanded === index ? false : index;
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: pose.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Accordion
      ref={setNodeRef}
      style={style}
      expanded={index === expanded}
      onChange={() => handleExpand(index)}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {pose.name}

        <Box flexGrow={1} />

        <Box
          display="flex"
          sx={{ touchAction: 'none' }}
          {...attributes}
          {...listeners}
        >
          <DragHandle sx={{ mx: 1, cursor: 'move' }} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <PoseEditor index={index} />
      </AccordionDetails>
    </Accordion>
  );
});

const PoseEditor = memo(({ index }: { index: number }) => {
  const [tab, setTab] = useState(0);

  const state = useCharacterExtraEditor();

  const handlePreview = () => {
    state.setPreviewIndex(index);
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={1}>
          <Chip
            label="Main"
            size="small"
            color={tab === 0 ? 'primary' : 'default'}
            onClick={() => setTab(0)}
          />
          <Chip
            label="Animations"
            size="small"
            color={tab === 1 ? 'primary' : 'default'}
            onClick={() => setTab(1)}
          />
          <Chip
            label="Sounds"
            size="small"
            color={tab === 2 ? 'primary' : 'default'}
            onClick={() => setTab(2)}
          />
          <Chip
            label="Effects"
            size="small"
            color={tab === 3 ? 'primary' : 'default'}
            onClick={() => setTab(3)}
          />
        </Stack>

        <Button
          variant="contained"
          startIcon={<RemoveRedEye />}
          onClick={handlePreview}
        >
          Preview
        </Button>
      </Stack>

      <MultiHideView index={tab}>
        <MainPoseEditor index={index} />
        <AnimationPoseEditor index={index} />
        <AudioPoseEditor index={index} />
        <EffectPoseEditor index={index} />
      </MultiHideView>
    </Stack>
  );
});

const Previewer = () => {
  const [frame, setFrame] = useState(undefined as CaseFrame | undefined);

  const characterFormTab = useCharacterFormTab();

  const state = useCharacterExtraEditor();
  const characterState = useCharacterEditor();

  const { character } = useProxy(characterState);
  const { setPreviewIndex, previewIndex } = useProxy(state);

  const onBack = useCallback(() => {
    setPreviewIndex(undefined);
  }, [setPreviewIndex]);

  useEffect(() => {
    if (previewIndex !== undefined) {
      setFrame({
        id: -1,
        text: 'This is sample text.',
        characterId: characterState.characterId,
        poseId: characterState.character.poses[previewIndex].id,
      });
    } else {
      setFrame(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewIndex]);

  useEffect(() => {
    if (!previewIndex) return;

    setPreviewIndex(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterFormTab]);

  return (
    <PlayerAssetsProvider value={{ character: { [character.id]: character } }}>
      <FramePreviewer frame={frame} onBack={onBack} />
    </PlayerAssetsProvider>
  );
};

const reorderPoses = async (characterId: number, poses: PoseDto[]) => {
  await ApiClient.assets.character.reorderPoses({
    characterId,
    poses: poses.map((p) => p.id),
  });

  assetActions.updateUserAssetLocally('character', characterId, { poses });
};
