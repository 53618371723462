import { Close } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  PaperProps,
  Stack,
} from '@mui/material';
import { BackgroundDto } from '@web/api/api';
import { useToggleValue } from '@web/hooks/useToggleValue';
import { useAssetStore } from '@web/store/assets/state';
import React, { forwardRef, useCallback, useState } from 'react';
import {
  AutocompleteWithFavorites,
  StyledAutocompletePopper,
} from '../../common/form/AutocompleteWithFavorites';
import { RenderAutocompleteInput } from '../../common/ui/RenderAutocompleteInput';
import { VirtualizedListbox } from '../../common/ui/VirtualizedListbox';
import { useAssetOptions } from '../hooks/useAssetOptions';

type BackgroundPickerProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  placeholder?: string;
} & Omit<
  React.ComponentProps<typeof AutocompleteWithFavorites>,
  'value' | 'options' | 'type' | 'onChange' | 'label'
>;

export const BackgroundPicker = ({
  value,
  onChange,
  ...props
}: BackgroundPickerProps) => {
  const {
    background: { preset, user },
  } = useAssetStore();

  const [filter, setFilter] = useToggleValue<'preset' | 'custom' | undefined>(
    undefined,
  );
  const [wide, setWide] = useState(false);

  const filterFunction = wide
    ? (option: BackgroundDto) => option.isWide === wide
    : undefined;

  const { selectedValue, sortedOptions } = useAssetOptions({
    value,
    filter,
    type: 'background',
    presetList: preset,
    userList: user,
    filterFunction,
  });

  const handleClear = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    onChange(null);
  };

  return (
    <AutocompleteWithFavorites
      label=""
      type="background"
      size="small"
      PaperComponent={BackgroundPickerPaper}
      ListboxComponent={BackgroundVirtualizedListBox}
      ListboxProps={{ style: { maxHeight: '322px' } }}
      PopperComponent={StyledAutocompletePopper}
      value={selectedValue}
      onChange={(_, value) =>
        onChange(typeof value?.id === 'number' ? value.id : null)
      }
      options={sortedOptions}
      slotProps={{
        paper: {
          filter,
          setFilter,
          wide,
          setWide,
        } as PaperProps,
      }}
      renderInput={(params) => (
        <Box display="flex">
          <IconButton size="small" sx={{ mr: 1 }} onClick={handleClear}>
            <Close />
          </IconButton>

          <RenderAutocompleteInput
            variant="standard"
            placeholder={props.placeholder ?? 'Change background'}
            params={params}
          />
        </Box>
      )}
      disableClearable
      {...props}
    />
  );
};

type BackgroundPickerPaperProps = {
  filter: 'preset' | 'custom' | undefined;
  setFilter: (filter: 'preset' | 'custom' | undefined) => void;
  wide: boolean;
  setWide: (value: boolean) => void;
} & PaperProps;

const BackgroundPickerPaper = (props: BackgroundPickerPaperProps) => {
  const { children, filter, setFilter, wide, setWide, ...other } = props;

  const handleChangeWideFilter = useCallback(
    (event: React.MouseEvent<HTMLLabelElement>, label = false) => {
      if (!label) {
        event.stopPropagation();
      } else {
        event.preventDefault();
      }

      setWide(!wide);
    },
    [setWide, wide],
  );

  return (
    <Paper {...other} onMouseDown={(event) => event.preventDefault()}>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            label="Preset"
            color={filter === 'preset' ? 'info' : 'default'}
            onClick={() => setFilter('preset')}
            size="small"
            clickable
          />
          <Chip
            label="Custom"
            color={filter === 'custom' ? 'info' : 'default'}
            onClick={() => setFilter('custom')}
            size="small"
            clickable
          />
        </Stack>

        <FormControlLabel
          label="Wide"
          onClick={(e) => handleChangeWideFilter(e, true)}
          control={
            <Checkbox
              checked={wide}
              onChange={() => handleChangeWideFilter}
              color="primary"
              size="small"
            />
          }
        />
      </Stack>

      {children}
    </Paper>
  );
};

const BackgroundVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={46} height={46 * 7}>
      {props.children}
    </VirtualizedListbox>
  );
});
