import { useCallback } from 'react';
import { usePlayer } from '../providers/PlayerProvider';

export const useNextButton = () => {
  const {
    state: { showNextButton },
    actions: { next },
    playerUi: {
      state: { size },
    },
  } = usePlayer();

  const onClick = useCallback(() => {
    next();
  }, [next]);

  return { onClick, showNextButton, size };
};
