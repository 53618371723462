import { proxy } from 'valtio';
import { subscribeKey, useProxy } from 'valtio/utils';
import { AssetType } from '../assets/state';

type RootStoreType = {
  assetsDialogTab: AssetType;
  loading?: boolean;
  theme: 'dark' | 'light';
  dialogs: {
    login: boolean;
    account: boolean;
    assets: boolean;
    myObjections: boolean;
    maintenance: boolean;
    beta: boolean;
  };
};

const getDefaultValue = <T>(key: string, defaultValue: T) => {
  const value = localStorage.getItem(key);
  if (value === null) {
    return defaultValue;
  }
  return value as T;
};

export const rootStore: RootStoreType = proxy({
  assetsDialogTab: getDefaultValue<AssetType>('assetsDialogTab', 'background'),
  theme: getDefaultValue<'dark' | 'light'>('theme', 'dark'),
  dialogs: {
    login: false,
    account: false,
    assets: false,
    myObjections: false,
    maintenance: false,
    beta: false,
  },
});

subscribeKey(rootStore, 'theme', () => {
  localStorage.setItem('theme', rootStore.theme);
});

subscribeKey(rootStore, 'assetsDialogTab', () => {
  localStorage.setItem('assetsDialogTab', rootStore.assetsDialogTab);
});

export const useRootStore = () => useProxy(rootStore);
