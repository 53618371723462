import { Backdrop, CircularProgress, Container } from '@mui/material';
import { AssetsDialog } from '@web/components/assets/dialogs/AssetsDialog';
import AccountDialog from '@web/components/auth/AccountDialog';
import LoginDialog from '@web/components/auth/LoginDialog';
import { BetaDialog } from '@web/components/common/dialogs/BetaDialog';
import { MaintenanceModeDialog } from '@web/components/common/dialogs/MaintenanceModeDialog';
import { MyObjectionsDialog } from '@web/components/common/dialogs/MyObjectionsDialog';
import { SnackbarProvider } from '@web/providers/snackbar/SnackbarProvider';
import { rootStore } from '@web/store/root/state';
import { Outlet } from 'react-router-dom';
import { useSnapshot } from 'valtio';

export const maxContainerWidth = 1280;

const BaseLayout = () => {
  return (
    <SnackbarProvider>
      <LoginDialog />
      <AccountDialog />
      <AssetsDialog />
      <MyObjectionsDialog />
      <MaintenanceModeDialog />
      <BetaDialog />

      <LoadingIndicator />

      <Container
        style={{ maxWidth: `${maxContainerWidth}px !important` }}
        disableGutters
      >
        <Outlet />
      </Container>
    </SnackbarProvider>
  );
};

const LoadingIndicator = () => {
  const { loading } = useSnapshot(rootStore);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BaseLayout;
