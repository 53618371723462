import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CaseFrame, type FrameAction } from '@shared/types';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { DialogTransitionGrow } from '@web/components/common/ui/Dialog';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import frameActions, { FrameActionItem } from '@web/data/frame-actions';
import { assetStore } from '@web/store/assets/state';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { SyntheticEvent, memo, useCallback, useMemo, useState } from 'react';
import {
  FrameAndIndexContext,
  FrameAndIndexProvider,
  useFrameAndIndexContext,
} from '../providers/FrameContextProvider';
import { CharacterPicker } from '../ui/CharacterPicker';
import { DialogueBoxPicker } from '../ui/DialogueBoxPicker';

export const FrameActionsDialog = memo(() => {
  const {
    frames,
    frameActionsFrameIndex,
    dialogs: { frameActions },
  } = useMakerStore();

  const handleClose = () => {
    makerStore.dialogs.frameActions = false;
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  if (
    (!frameActionsFrameIndex && frameActionsFrameIndex !== 0) ||
    !frames.length
  ) {
    return null;
  }

  const contextValue: FrameAndIndexContext = {
    frame: frames[frameActionsFrameIndex] || ({} as CaseFrame),
    index: frameActionsFrameIndex ?? 0,
  };

  return (
    <DraggableDialog
      open={!!frameActions}
      onClose={handleClose}
      titleComponent={<FrameActionsDialogTitle />}
      TransitionComponent={DialogTransitionGrow}
      transitionDuration={200}
      maxWidth="sm"
      fullscreen={sm}
      hideBackdrop={false}
      disableEnforceFocus
      fullWidth
    >
      <DialogContent sx={{ p: { xs: 1, sm: 2 } }}>
        <FrameAndIndexProvider value={contextValue}>
          <FrameActionsDialogContent />
        </FrameAndIndexProvider>
      </DialogContent>
    </DraggableDialog>
  );
});

const FrameActionsDialogContent = memo(() => {
  const [expanded, setExpanded] = useState<number | false>(0);
  const { frame, index } = useFrameAndIndexContext();

  const handleAddFrameAction = () => {
    if ((!index && index !== 0) || frame?.actions?.length === 10) {
      return;
    }

    makerStore.frames[index].actions = [
      ...(makerStore.frames[index].actions ?? []),
      { actionId: 1, actionParam: '0' },
    ];
  };

  if (!frame) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Button
        startIcon={<Add />}
        color="info"
        variant="contained"
        disabled={(frame?.actions?.length ?? 0) >= 10}
        onClick={handleAddFrameAction}
        fullWidth
      >
        Add Action
      </Button>

      <Box minHeight="65vh" overflow="auto">
        {frame.actions &&
          frame.actions.length > 0 &&
          frame.actions.map((frameAction, index) => {
            return (
              <FrameActionAccordion
                key={index}
                frameAction={frameAction}
                index={index}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            );
          })}
      </Box>
    </Stack>
  );
});

type FrameActionAccordionProps = {
  frameAction: FrameAction;
  index: number;
  expanded: number | false;
  setExpanded: (value: number | false) => void;
};

const FrameActionAccordion = memo(
  ({
    frameAction,
    index,
    expanded,
    setExpanded,
  }: FrameActionAccordionProps) => {
    const { frameActionsFrameIndex } = useMakerStore();

    const handleRemoveAction = useCallback(
      (index: number) => (event: React.SyntheticEvent) => {
        makerStore.frames[frameActionsFrameIndex!]?.actions?.splice(index, 1);
      },
      [frameActionsFrameIndex],
    );

    const handleChange = useCallback(
      (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? index : false);
      },
      [setExpanded],
    );

    return (
      <Accordion
        key={index}
        expanded={expanded === index}
        onChange={handleChange(index)}
        slotProps={{ transition: { unmountOnExit: true } }}
        square
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Action {index + 1}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <FrameAction frameAction={frameAction} index={index} />
            <Box alignSelf="end">
              <Button
                size="small"
                color="inherit"
                onClick={handleRemoveAction(index)}
              >
                Remove Action
              </Button>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
);

const FrameAction = memo(
  ({ frameAction, index }: { frameAction: FrameAction; index: number }) => {
    const { frame, index: frameActionsFrameIndex } = useFrameAndIndexContext();

    const currentAction = frameActions.find(
      (a: FrameActionItem) => a.id === frameAction.actionId,
    );

    const frameActionsOptions = useMemo(() => {
      return frameActions
        .filter((f) => !f.hide || frame.actions?.[index].actionId === f.id)
        .sort((a, b) => a.text.localeCompare(b.text));
    }, [frame.actions, index]);

    const handleChange = useCallback(
      (
        _e: SyntheticEvent<Element, Event>,
        newValue: FrameActionItem | null,
      ) => {
        if (!newValue) {
          return;
        }

        const newAction: FrameActionItem =
          frameActions.find((a: FrameActionItem) => a.id === newValue.id) ??
          frameActions[0];

        makerStore.frames[frameActionsFrameIndex].actions![index] = {
          actionId: newValue.id,
          actionParam: getFrameActionDefaultValue(newAction),
        };
      },
      [frameActionsFrameIndex, index],
    );

    if (!currentAction) {
      return null;
    }

    return (
      <Stack spacing={1}>
        <Autocomplete
          value={currentAction}
          options={frameActionsOptions}
          onChange={handleChange}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              label="Action"
              variant="outlined"
              color="info"
            />
          )}
          size="small"
          color="info"
          getOptionLabel={(option) => option.text}
          getOptionKey={(option) => option.id.toString()}
          renderOption={(props, option, { selected }) => {
            return (
              <Box
                component="li"
                sx={{
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                {...props}
              >
                <Stack spacing={1}>
                  {option.text}
                  {option.hint && (
                    <Typography variant="caption" color="text.secondary">
                      {option.hint}
                    </Typography>
                  )}
                </Stack>
              </Box>
            );
          }}
          disableClearable
          blurOnSelect
          fullWidth
        />

        <FrameActionInput
          currentAction={currentAction}
          frameAction={frameAction}
          index={index}
        />
      </Stack>
    );
  },
);

const FrameActionInput = memo(
  ({
    currentAction,
    frameAction,
    index,
  }: {
    currentAction: FrameActionItem;
    frameAction: FrameAction;
    index: number;
  }) => {
    const { index: frameActionsFrameIndex } = useFrameAndIndexContext();

    const currentSelectValue = useMemo(
      () =>
        currentAction.inputType === 'select'
          ? (currentAction.options.find(
              (o) => o.value === frameAction.actionParam,
            ) ?? null)
          : null,
      [currentAction, frameAction.actionParam],
    );

    const currentSelectDialogueBox = useMemo(
      () =>
        currentAction.inputType === 'dialogueBox'
          ? (assetStore.dialogueBox.cache[frameAction.actionParam]?.id ?? null)
          : null,
      [currentAction.inputType, frameAction.actionParam],
    );

    const handleSelectChange = useCallback(
      (
        _e: SyntheticEvent<Element, Event>,
        newValue: { text: string; value: string } | null,
      ) => {
        if (!newValue) {
          return;
        }

        makerStore.frames[frameActionsFrameIndex].actions![index].actionParam =
          newValue.value;
      },
      [frameActionsFrameIndex, index],
    );

    const handleDialogueBoxSelectChange = useCallback(
      (value: string | null) => {
        if (!value) {
          return;
        }

        makerStore.frames[frameActionsFrameIndex].actions![index].actionParam =
          String(value);
      },
      [frameActionsFrameIndex, index],
    );

    const handleCharacterSelectChange = useCallback(
      (value: number | null) => {
        if (!value) {
          return;
        }

        makerStore.frames[frameActionsFrameIndex].actions![index].actionParam =
          String(value);
      },
      [frameActionsFrameIndex, index],
    );

    const currentTextFieldValue = useMemo(() => {
      if (currentAction.inputType === 'number') {
        return frameAction.actionParam;
      }

      return '';
    }, [currentAction, frameAction.actionParam]);

    const handleTextFieldChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        makerStore.frames[frameActionsFrameIndex].actions![index].actionParam =
          e.target.value;
      },
      [frameActionsFrameIndex, index],
    );

    switch (currentAction.inputType) {
      case 'select':
        return (
          <Autocomplete
            value={currentSelectValue}
            options={currentAction.options}
            onChange={handleSelectChange}
            renderInput={(params) => (
              <RenderAutocompleteInput
                params={params}
                variant="outlined"
                helperText={currentSelectValue?.hint}
              />
            )}
            size="small"
            color="info"
            getOptionLabel={(option) => option.text}
            getOptionKey={(option) => option.value}
            disableClearable={currentSelectValue !== null}
            blurOnSelect
            fullWidth
          />
        );
      case 'number':
        return (
          <NumberTextField
            value={currentTextFieldValue}
            onChange={handleTextFieldChange}
            helperText={currentSelectValue?.hint}
            variant="outlined"
            size="small"
          />
        );
      case 'dialogueBox':
        return (
          <DialogueBoxPicker
            value={currentSelectDialogueBox || null}
            onChange={handleDialogueBoxSelectChange}
            variant="outlined"
            title=""
          />
        );
      case 'galleryCharacter':
        return (
          <CharacterPicker
            value={parseInt(frameAction.actionParam)}
            onChange={handleCharacterSelectChange}
            customFilter={(option) => !!option.galleryImageUrl}
          />
        );
      case 'galleryCharacterAj':
        return (
          <CharacterPicker
            value={parseInt(frameAction.actionParam)}
            onChange={handleCharacterSelectChange}
            customFilter={(option) => !!option.galleryAJImageUrl}
          />
        );

      default:
        return null;
    }
  },
);

const FrameActionsDialogTitle = () => {
  const { frameActionsFrameIndex, isCaseProject } = useMakerStore();

  const title = `Frame Actions [${isCaseProject ? makerStore.frames[frameActionsFrameIndex!]?.id : frameActionsFrameIndex! + 1}]`;

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    makerStore.dialogs.frameActions = false;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6">{title}</Typography>

      <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
    </Stack>
  );
};

const getFrameActionDefaultValue = (frameAction: FrameActionItem) => {
  if (frameAction.defaultValue) {
    return String(frameAction.defaultValue);
  }

  if (frameAction.inputType === 'select') {
    return frameAction.options[0].value;
  }

  return '';
};
