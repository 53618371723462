import { Edit, Share } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import { CaseFrame } from '@shared/types';
import { CharacterDto } from '@web/api/api';
import { useAssetStore } from '@web/store/assets/state';
import { memo, useMemo } from 'react';
import { Thumbnail } from '../../thumbnail/Thumbnail';
import { AssetsDataGrid } from '../AssetsDataGrid';
import { SharePopper } from '../AssetSharing';

type CharacterAssetsProps = {
  onSelectChange?: (selection: (string | number)[]) => void;
  onAction?: (action: 'edit', id: number) => void;
} & Partial<DataGridProps>;

export const DataGridCharacters = memo(
  ({ onSelectChange, onAction, ...props }: CharacterAssetsProps) => {
    const {
      character: { user },
    } = useAssetStore();

    const rows = useMemo(() => [...user], [user]);

    const columns = useMemo<GridColDef<CharacterDto>[]>(
      () => [
        { field: 'id', headerName: 'ID', width: 100, hideSortIcons: false },
        {
          field: 'url',
          headerName: 'Character',
          renderCell: (params) => (
            <RenderThumbnailCell character={params.row} />
          ),
          width: 120,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 240,
        },
        {
          field: 'side',
          headerName: 'Location',
          width: 120,
          renderCell: (params) => <Chip size="small" label={params.row.side} />,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                onAction?.('edit', params.id as number);
              }}
            />,
            <SharePopper id={params.row.id} type="character">
              {({ toggle }) => (
                <GridActionsCellItem
                  icon={<Share />}
                  label="Share"
                  onClick={toggle}
                  disabled={params.row.poses?.length === 0}
                />
              )}
            </SharePopper>,
          ],
        },
      ],
      [onAction],
    );

    return (
      <AssetsDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={66.67}
        onSelectChange={onSelectChange}
      />
    );
  },
);

const RenderThumbnailCell = ({ character }: { character: CharacterDto }) => {
  const frame: CaseFrame = useMemo(
    () => ({
      id: -1,
      text: '',
      characterId: character.id,
      poseId: character.poses?.[0]?.id,
    }),
    [character],
  );

  return (
    <Box
      minWidth={100}
      sx={{
        '&:hover': {
          transform: 'scale(2)',
          position: 'absolute',
          zIndex: 9,
          transition: 'transform 0.15s',
        },
      }}
    >
      <Thumbnail frame={frame} />
    </Box>
  );
};
