import _ from 'lodash';
import { SettingsStoreType, settingsStore } from './state';

export const settingsActions = {
  load() {
    const settings = localStorage.getItem('settings');

    if (settings) {
      const parsedSettings = JSON.parse(settings);

      settingsStore.favorite = _.merge(
        settingsStore.favorite,
        parsedSettings.favorite,
      );

      settingsStore.audio = _.merge(settingsStore.audio, parsedSettings.audio);

      settingsStore.enableBackups = parsedSettings.enableBackups;
    }
  },
  addFavorite(id: string | number, type: keyof SettingsStoreType['favorite']) {
    settingsStore.favorite[type] = [...settingsStore.favorite[type], id];
  },
  removeFavorite(
    id: string | number,
    type: keyof SettingsStoreType['favorite'],
  ) {
    settingsStore.favorite[type] = settingsStore.favorite[type].filter(
      (favoriteId) => favoriteId !== id,
    );
  },
};
