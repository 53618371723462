import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { rootStore, useRootStore } from '@web/store/root/state';
import React from 'react';

export const BetaDialog = React.memo(() => {
  const {
    dialogs: { beta },
  } = useRootStore();

  const handleClose = () => {
    rootStore.dialogs.beta = false;

    localStorage.setItem('beta', 'true');
  };

  return (
    <Dialog open={beta} maxWidth="xs" fullWidth>
      <DialogTitle>Beta Notes</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Stack spacing={2}>
          <List
            sx={{ listStyle: 'disc', paddingLeft: 2, color: 'text.secondary' }}
            dense
          >
            <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
              <ListItemText
                color="text.secondary"
                primary="This is a beta version intended for testing purposes."
              />
            </ListItem>
            <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
              <ListItemText primary="All data will be wiped after the beta period." />
            </ListItem>
            <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
              <ListItemText primary="Export MP4 is unavailable during the beta." />
            </ListItem>
            <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
              <ListItemText primary="Please report any issues by email or on X (Twitter). See page footer for contact information." />
            </ListItem>

            <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
              <ListItemText primary="Thank you for your support!" />
            </ListItem>
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
});
