import { Edit, Share } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import { DialogueBoxDto } from '@web/api/api';
import { Container } from '@web/components/player/ui/Container';
import {
  DialogueBoxContainer,
  DialogueBoxImage,
} from '@web/components/player/ui/Dialogue';
import { ZIndex } from '@web/components/player/utils/constants';
import { useAssetStore } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { DialogueBox } from '@web/types/project';
import { CSSProperties, memo, useMemo } from 'react';
import { AssetsDataGrid } from '../AssetsDataGrid';
import { SharePopper } from '../AssetSharing';

type DialogueBoxAssetsProps = {
  onSelectChange?: (selection: (string | number)[]) => void;
  onAction?: (action: 'edit', id: number) => void;
} & Partial<DataGridProps>;

export const DataGridDialogueBox = memo(
  ({ onSelectChange, onAction, ...props }: DialogueBoxAssetsProps) => {
    const {
      dialogueBox: { user },
    } = useAssetStore();

    const rows = useMemo(() => [...user], [user]);

    const columns = useMemo<GridColDef<DialogueBoxDto>[]>(
      () => [
        { field: 'id', headerName: 'ID', width: 100, hideSortIcons: false },
        {
          field: 'url',
          headerName: 'Dialogue Box',
          renderCell: (params) => (
            <RenderThumbnailCell dialogueBox={params.row} />
          ),
          width: 120,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 320,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                onAction?.('edit', params.id as number);
              }}
            />,
            <SharePopper id={params.row.id} type="dialogueBox">
              {({ toggle }) => (
                <GridActionsCellItem
                  icon={<Share />}
                  label="Share"
                  onClick={toggle}
                />
              )}
            </SharePopper>,
          ],
        },
      ],
      [onAction],
    );

    return (
      <AssetsDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={66.67}
        onSelectChange={onSelectChange}
      />
    );
  },
);

const RenderThumbnailCell = ({ dialogueBox }: { dialogueBox: DialogueBox }) => {
  const { aspectRatio } = useMakerStore();
  const { url, opacity, imageOpacity } = dialogueBox;

  const containerStyle = useMemo<CSSProperties>(
    () => ({
      opacity,
    }),
    [opacity],
  );

  return (
    <Box
      minWidth={100}
      sx={{
        '&:hover': {
          transform: 'scale(2)',
          position: 'absolute',
          zIndex: ZIndex.NextButton,
          transition: 'transform 0.15s',
        },
      }}
    >
      <Container
        aspectRatio={aspectRatio}
        sx={{ backgroundColor: 'transparent' }}
      >
        <DialogueBoxContainer style={containerStyle}>
          <DialogueBoxImage
            alt="Dialogue Box"
            src={url}
            style={{
              opacity: imageOpacity,
            }}
          />
        </DialogueBoxContainer>
      </Container>
    </Box>
  );
};
