import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Character_Alignment, Character_Side } from '@shared/types';
import { UpdateCharacterDto } from '@web/api/api';
import { BackgroundInput } from '@web/components/common/form/BackgroundInput';
import { SliderWithInput } from '@web/components/common/form/SliderWithInput';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import MultiView from '@web/components/common/ui/MultiView';
import { DualGridPicker } from '@web/components/maker/ui/DualGridPicker';
import { Background } from '@web/components/player/ui/Background';
import { Character } from '@web/components/player/ui/Character';
import { Container } from '@web/components/player/ui/Container';
import { Desk } from '@web/components/player/ui/Desk';
import { Viewport } from '@web/components/player/ui/Viewport';
import { assetActions } from '@web/store/assets/actions';
import { assetStore } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { useRootStore } from '@web/store/root/state';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect, useRef } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { proxy, useSnapshot } from 'valtio';
import * as yup from 'yup';
import Form from '../../common/form/Form';
import { assetDialogState } from '../dialogs/AssetsDialogState';
import { advancedCharacterFormState as state } from './AdvancedCharacterFormState';
import { AlignmentInput } from './AlignmentInput';
import { CharacterUpdateWatcher } from './CharacterUpdateWatchers';
import { LocationInput } from './LocationInput';
import { SpeechBlipInput } from './SpeechBlipInput';
import { PosesEditor } from './pose/PoseEditor';
import {
  CharacterEditorContextValue,
  CharacterEditorProvider,
  useCharacterEditor,
} from './providers/CharacterEditorProvider';
import { SpeechBubblesEditor } from './speechBubble/SpeechBubbleEditor';

export const AdvancedCharacterForm = memo(() => {
  const characterId = assetDialogState.editingAssetId || 0;

  const characterEditorState = useRef(
    proxy<CharacterEditorContextValue>({
      characterId: Number(characterId),
      characterIndex: assetStore.character.user.findIndex(
        (f) => f.id === characterId,
      ),
      character: JSON.parse(JSON.stringify(assetDialogState.editingAsset)),
    }),
  ).current;

  const { tab } = useSnapshot(state);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    state.tab = newValue;
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{ mb: 2 }}
        textColor="inherit"
      >
        <Tab label="Main" />
        <Tab label="Poses" />
        <Tab label="Speech Bubbles" />
      </Tabs>

      <CharacterEditorProvider value={characterEditorState}>
        <MultiHideView index={tab}>
          <MainTab />
          <PosesTab />
          <SpeechBubblesTab />
        </MultiHideView>
      </CharacterEditorProvider>
    </>
  );
});

const PosesTab = memo(() => {
  return <PosesEditor />;
});

const SpeechBubblesTab = memo(() => {
  return <SpeechBubblesEditor />;
});

const MainTab = memo(() => {
  const { mainFormTab } = useSnapshot(state);
  const {
    dialogs: { assets },
  } = useRootStore();

  useEffect(() => {
    if (!assets) {
      state.mainFormTab = 0;
    }
  }, [assets]);

  return (
    <MultiView index={mainFormTab}>
      <MainCharacterForm />
      <OffsetChangeForm />
    </MultiView>
  );
});

type OffsetChangeFormType = {
  offsetX: number;
  offsetY: number;
};

const OffsetChangeForm = memo(() => {
  const characterState = useCharacterEditor();

  const schema = yup.object().shape({
    offsetX: yup.number().required().min(-100).max(100),
    offsetY: yup.number().required().min(-100).max(100),
  });

  const defaultValue: OffsetChangeFormType = {
    offsetX: characterState.character.offsetX,
    offsetY: characterState.character.offsetY,
  };

  const onSubmit = async (data: OffsetChangeFormType) => {
    await assetActions.updateAsset(
      'character',
      characterState.characterId,
      data as UpdateCharacterDto,
    );

    characterState.character = {
      ...characterState.character,
      ...data,
    };

    enqueueSnackbar('Offset saved', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  const handleCancel = () => {
    state.mainFormTab = 0;
  };

  return (
    <Form schema={schema} onSubmit={onSubmit} defaultValues={defaultValue}>
      {({ control, errors, loading }) => (
        <Stack spacing={2}>
          <OffsetPreview control={control} />

          {errors.root?.message && (
            <Alert severity="error" variant="filled">
              {errors.root.message}
            </Alert>
          )}

          <DualGridPicker>
            <Controller
              control={control}
              name="offsetX"
              render={({ field }) => (
                <SliderWithInput
                  label="Offset X"
                  value={field.value}
                  onChange={field.onChange}
                  min={-100}
                  max={100}
                />
              )}
            />

            <Controller
              control={control}
              name="offsetY"
              render={({ field }) => (
                <SliderWithInput
                  label="Offset Y"
                  value={field.value}
                  onChange={field.onChange}
                  min={-100}
                  max={100}
                />
              )}
            />
          </DualGridPicker>

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Save Offset
            </Button>

            <Button variant="outlined" onClick={handleCancel}>
              Back
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
});

const OffsetPreview = ({
  control,
}: {
  control: Control<OffsetChangeFormType>;
}) => {
  const { aspectRatio } = useMakerStore();

  const [offsetX, offsetY] = useWatch({
    control,
    name: ['offsetX', 'offsetY'],
  });

  const characterState = useCharacterEditor();
  const { character } = useSnapshot(characterState);

  const background = assetStore.background.cache[character.backgroundId];
  const pose = character.poses[0];

  const fullWidth = !background?.isWide || pose?.isSpeedlines; // for viewport

  return (
    <Container aspectRatio={aspectRatio}>
      <Viewport fullWidth={fullWidth}>
        <Background url={background.url} isWide={background.isWide} />
        <Desk url={background.deskUrl} isWide={background.isWide} />
        <Character
          offsetX={offsetX}
          offsetY={offsetY}
          url={pose.idleImageUrl}
          alignment={character.alignment}
          limitWidth={character.limitWidth}
          side={character.side as Character_Side}
        />
      </Viewport>
    </Container>
  );
};

const MainCharacterForm = memo(() => {
  const characterState = useCharacterEditor();

  const defaultValue: UpdateCharacterDto = (({
    name,
    nameplate,
    side,
    alignment,
    backgroundId,
    blipUrl,
    iconUrl,
    galleryImageUrl,
    galleryAJImageUrl,
    limitWidth,
    offsetX,
    offsetY,
  }) => ({
    name,
    nameplate,
    side: side as Character_Side,
    alignment: alignment as Character_Alignment,
    backgroundId,
    blipUrl,
    iconUrl,
    galleryImageUrl,
    galleryAJImageUrl,
    limitWidth,
    offsetX,
    offsetY,
  }))(characterState.character);

  const schema = yup.object<UpdateCharacterDto>().shape({
    id: yup.number().optional(),
    name: yup.string().max(80).required(),
    nameplate: yup.string().max(40).required(),
    side: yup.string().required().oneOf(Object.values(Character_Side)),
    alignment: yup.number().required().min(0).max(2),
    backgroundId: yup.number().required(),
    blipUrl: urlValidation().required(),
    iconUrl: urlValidation().nullable(),
    galleryImageUrl: urlValidation().nullable(),
    galleryAJImageUrl: urlValidation().nullable(),
    limitWidth: yup.boolean().nullable(),
  });

  const onSubmit = async (data: UpdateCharacterDto) => {
    await assetActions.updateAsset(
      'character',
      characterState.characterId,
      data,
    );

    characterState.character = {
      ...characterState.character,
      ...data,
    };

    enqueueSnackbar('Character saved', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  const handleBack = () => {
    assetDialogState.editingAsset = undefined;
  };

  return (
    <Form
      schema={schema}
      onSubmit={onSubmit}
      defaultValues={defaultValue}
      mode="onChange"
    >
      {({ control, register, errors, loading }) => (
        <>
          <Stack spacing={2}>
            <Stack spacing={2}>
              {errors.root?.message && (
                <Alert severity="error" variant="filled">
                  {errors.root.message}
                </Alert>
              )}

              <DualGridPicker mobileMb={2}>
                <TextField
                  label="Name"
                  {...register('name')}
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                />

                <TextField
                  label="Name Plate"
                  {...register('nameplate')}
                  variant="standard"
                  error={!!errors.nameplate}
                  helperText={errors.nameplate?.message}
                  fullWidth
                />
              </DualGridPicker>

              <Stack direction="row" spacing={2}>
                <LocationInput control={control} variant="standard" />
                <AlignmentInput control={control} variant="standard" />
              </Stack>

              <Controller
                control={control}
                name="backgroundId"
                render={({ field }) => (
                  <BackgroundInput
                    label="Default Background"
                    allowWide={false}
                    value={field.value}
                    onChange={field.onChange}
                    variant="standard"
                    error={errors.backgroundId?.message}
                  />
                )}
              />

              <SpeechBlipInput control={control} variant="standard" />
            </Stack>

            <Box />

            <TextField
              label="Icon Image URL"
              {...register('iconUrl')}
              variant="standard"
              error={!!errors.iconUrl}
              helperText={errors.iconUrl?.message}
              fullWidth
            />

            <TextField
              label="Gallery Sprite Image URL"
              {...register('galleryImageUrl')}
              variant="standard"
              error={!!errors.galleryImageUrl}
              helperText={errors.galleryImageUrl?.message}
              fullWidth
            />

            <TextField
              label="Gallery Sprite Image URL (AJ)"
              {...register('galleryAJImageUrl')}
              variant="standard"
              error={!!errors.galleryAJImageUrl}
              helperText={errors.galleryAJImageUrl?.message}
              fullWidth
            />

            <Box>
              <ConfigureOffsetButton />
            </Box>

            <Controller
              control={control}
              name="limitWidth"
              render={({ field }) => (
                <Box>
                  <Stack>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      }
                      label="Constraint Width"
                    />

                    <Typography variant="caption" color="text.secondary">
                      Sets a maximum width for the character
                    </Typography>
                  </Stack>
                </Box>
              )}
            />

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Save Character
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>
            </Stack>
          </Stack>

          <CharacterUpdateWatcher control={control} />
        </>
      )}
    </Form>
  );
});

const ConfigureOffsetButton = () => {
  const characterState = useCharacterEditor();
  const { character } = useSnapshot(characterState);

  const disabled = !character || character.poses.length === 0;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        variant="contained"
        color="success"
        size="small"
        onClick={() => {
          state.mainFormTab = 1;
        }}
        disabled={disabled}
      >
        Configure Offset
      </Button>

      <Typography
        variant="caption"
        color="text.secondary"
        display={disabled ? 'inherit' : 'none'}
      >
        (min. 1 pose required)
      </Typography>
    </Stack>
  );
};
