import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  BackgroundDto,
  CreateBackgroundDto,
  UpdateBackgroundDto,
} from '@web/api/api';
import { assetActions } from '@web/store/assets/actions';
import { useAssetStore } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { defaultBackground } from '@web/utils/project';
import { urlValidation } from '@web/utils/yup';
import { memo, useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Form from '../../common/form/Form';
import { RenderAutocompleteInput } from '../../common/ui/RenderAutocompleteInput';
import { DualGridPicker } from '../../maker/ui/DualGridPicker';
import { Background } from '../../player/ui/Background';
import { Container } from '../../player/ui/Container';
import { Desk } from '../../player/ui/Desk';
import { assetDialogState } from '../dialogs/AssetsDialogState';

export const BackgroundForm = memo(
  ({ onAdded }: { onAdded?: (data: BackgroundDto) => void }) => {
    const schema = yup.object().shape({
      id: yup.number().optional(),
      name: yup.string().required(),
      url: urlValidation().required(),
      deskUrl: urlValidation().optional().nullable(),
      isWide: yup.boolean().optional(),
    });

    const queryClient = useQueryClient();

    const onSubmit = async (
      data: CreateBackgroundDto | UpdateBackgroundDto,
    ) => {
      if (assetDialogState.editingAssetId) {
        await assetActions.updateAsset(
          'background',
          assetDialogState.editingAssetId,
          data,
        );

        queryClient.invalidateQueries({
          queryKey: [
            'thumbnail',
            'background',
            assetDialogState.editingAssetId,
          ],
        });
      } else {
        const asset = await assetActions.addAsset('background', data);

        onAdded?.(asset);
      }

      assetDialogState.editingAsset = undefined;
    };

    const handleCancel = () => {
      assetDialogState.editingAsset = undefined;
    };

    return (
      <Form
        schema={schema}
        onSubmit={onSubmit}
        defaultValues={assetDialogState.editingAsset}
        mode="onChange"
      >
        {({ control, register, errors, loading }) => (
          <Stack spacing={2}>
            <Typography variant="h6">
              {assetDialogState.editingAssetId ? 'Edit' : 'Add'} Background
            </Typography>

            <DualGridPicker>
              <Stack spacing={2}>
                {errors.root?.message && (
                  <Alert severity="error" variant="filled">
                    {errors.root.message}
                  </Alert>
                )}

                <TextField
                  label="Name"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  autoFocus
                  fullWidth
                />

                <TextField
                  label="Image URL"
                  {...register('url')}
                  error={!!errors.url}
                  helperText={errors.url?.message}
                  fullWidth
                />

                <DeskInput control={control} />

                <Controller
                  control={control}
                  name="isWide"
                  render={({ field }) => (
                    <Box>
                      <FormControlLabel
                        label="Wide"
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={field.value ?? false}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                      />
                    </Box>
                  )}
                />
              </Stack>

              <BackgroundPreview control={control} />
            </DualGridPicker>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {assetDialogState.editingAssetId ? 'Edit' : 'Add'}
              </Button>

              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Form>
    );
  },
);

const DeskInput = ({
  control,
}: {
  control: Control<CreateBackgroundDto | UpdateBackgroundDto> | undefined;
}) => {
  const deskUrl = useWatch({ control, name: 'deskUrl' });
  const { errors } = useFormState({ control });

  const {
    background: { preset },
  } = useAssetStore();

  const deskOptions = useMemo(() => {
    return (
      preset
        ?.filter((f) => f.deskUrl)
        .map((p) => ({
          title: p.name,
          url: p.deskUrl,
        })) || []
    );
  }, [preset]);

  const selectedValue = useMemo(
    () => deskOptions.find((f) => f.url === deskUrl)?.url || deskUrl || null,
    [deskOptions, deskUrl],
  );

  return (
    <Controller
      control={control}
      name="deskUrl"
      render={({ field }) => (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            if (typeof newValue === 'object' && newValue !== null) {
              field.onChange(newValue.url);
            }
          }}
          onInputChange={(event, newInputValue) => {
            field.onChange(newInputValue);
          }}
          options={deskOptions}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              variant="outlined"
              error={!!errors.deskUrl}
              helperText={errors.deskUrl?.message}
              label="Desk Image URL"
              search={true}
            />
          )}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option.title || '';
          }}
          getOptionKey={(option) => {
            if (typeof option === 'string') return option;
            return option.url!;
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') return false;
            return option.url === value.url;
          }}
          freeSolo
        />
      )}
    />
  );
};

const BackgroundPreview = ({
  control,
}: {
  control: Control<CreateBackgroundDto | UpdateBackgroundDto> | undefined;
}) => {
  const { aspectRatio } = useMakerStore();

  const [url, deskUrl, isWide] = useWatch({
    control,
    name: ['url', 'deskUrl', 'isWide'],
  });
  const { errors } = useFormState({ control });

  const bg = {
    url: url && !errors.url ? url : defaultBackground.url,
    deskUrl: url && !errors.url ? deskUrl : undefined,
  };

  return (
    <Container aspectRatio={aspectRatio}>
      <Background url={bg.url} isWide={isWide} />
      <Desk url={bg.deskUrl} isWide={isWide} />
    </Container>
  );
};
