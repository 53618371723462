import { yupResolver } from '@hookform/resolvers/yup';
import { Error, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { CreateDialogueBoxDto } from '@web/api/api';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { ColorInput } from '@web/components/common/form/ColorInput';
import { setFormErrors } from '@web/components/common/form/Form';
import { SliderWithInput } from '@web/components/common/form/SliderWithInput';
import {
  getNextButtonType,
  NextButton,
} from '@web/components/player/dialogue/NextButton';
import {
  useDialogueBoxStyles,
  useNameplateStyles,
} from '@web/components/player/hooks/useDialogueBoxStyles';
import { usePlayerSize } from '@web/components/player/hooks/usePlayerSize';
import { Background } from '@web/components/player/ui/Background';
import {
  DialogueBoxContainer,
  DialogueBoxImage,
  DialogueBoxTextContainer,
} from '@web/components/player/ui/Dialogue';
import {
  NameplateContainer,
  NameplateImage,
  NameplateTextContainer,
} from '@web/components/player/ui/Nameplate';
import {
  DefaultDialogueBlueTextColor,
  DefaultDialogueGreenTextColor,
  DefaultDialogueRedTextColor,
  DefaultDialogueTextColor,
} from '@web/components/player/utils/constants';
import { PresetDialogueBoxes } from '@web/data/preset/preset-dialogue-boxes';
import { useFont } from '@web/hooks/useFont';
import { assetActions } from '@web/store/assets/actions';
import { usePreviewBackground } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { DialogueBox } from '@web/types/project';
import { getErrorMessage, ValidationError } from '@web/utils/error';
import { urlValidation } from '@web/utils/yup';
import { md5 } from 'js-md5';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useForm,
  UseFormSetValue,
  useFormState,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import { DualGridPicker } from '../../maker/ui/DualGridPicker';
import { Container } from '../../player/ui/Container';
import { Desk } from '../../player/ui/Desk';
import { assetDialogState } from '../dialogs/AssetsDialogState';
import { NextButtonIconInput } from '../ui/NextButtonIconInput';

export const DialogueBoxForm = memo(
  ({ onAdded }: { onAdded?: (data: DialogueBox) => void }) => {
    const queryClient = useQueryClient();

    const schema = yup.object<DialogueBox>().shape({
      id: yup.string().optional(),
      name: yup.string().required(),
      url: urlValidation().required(),
      wordSpacing: yup.number().min(0).max(100).nullable(),
      red: yup.string().min(6).max(6).nullable(),
      green: yup.string().min(6).max(6).nullable(),
      blue: yup.string().min(6).max(6).nullable(),
      nameplate: yup
        .object()
        .shape({
          url: urlValidation().required(),
          fontSize: yup.number().min(0).max(100).required(),
          fontUrl: urlValidation().required(),
          replaceBox: yup.boolean().nullable(),
          top: yup.number().min(-100).max(100).required(),
          right: yup.number().min(-100).max(100).required(),
        })
        .required(),
      text: yup
        .object()
        .shape({
          fontUrl: urlValidation().required(),
          fontSize: yup.number().min(0).max(100).required(),
          width: yup.number().min(0).max(100).required(),
          height: yup.number().min(0).max(100).required(),
          lineHeight: yup.number().min(0).max(100).required(),
          top: yup.number().min(-100).max(100).required(),
          left: yup.number().min(-100).max(100).required(),
        })
        .required(),
      nextButton: yup.object().shape({
        url: urlValidation().nullable(),
        icon: yup.string().required(),
        iconAnimDuration: yup.number().min(0).max(100).required(),
        iconColor: yup.string().min(6).max(6).required(),
        iconSize: yup.number().min(0).max(100).required(),
        left: yup.number().min(-100).max(100).required(),
        top: yup.number().min(-100).max(100).required(),
      }),
    });

    const defaultValues = useMemo<Partial<CreateDialogueBoxDto>>(
      () => ({
        red: 'F77337',
        green: '00F61C',
        blue: '6BC7F6',
        text: {
          fontSize: 34,
          width: 67,
          height: 4.518,
          left: 23.2,
          top: 69,
          lineHeight: 1.4,
        } as CreateDialogueBoxDto['text'],
        nameplate: {
          fontSize: 29,
          top: 62.9,
          right: 31.7,
          replaceBox: false,
        } as CreateDialogueBoxDto['nameplate'],
        nextButton: {
          icon: 'keyboarddoublearrowright',
          iconAnimDuration: 0.5,
          iconSize: 3.75,
          iconColor: 'FFD560',
          top: 75.7,
          left: 91,
        },
      }),
      [],
    );

    const {
      control,
      setValue,
      handleSubmit,
      formState: { errors, isSubmitting },
      setError,
      clearErrors,
      trigger,
    } = useForm<CreateDialogueBoxDto>({
      defaultValues: assetDialogState.editingAssetId
        ? (assetDialogState.editingAsset as CreateDialogueBoxDto)
        : defaultValues,
      mode: 'onBlur',
      resolver: yupResolver(schema),
    });

    const onSubmit = async (data: CreateDialogueBoxDto) => {
      try {
        clearErrors();

        if (assetDialogState.editingAssetId) {
          await assetActions.updateAsset(
            'dialogueBox',
            assetDialogState.editingAssetId,
            data,
          );

          queryClient.invalidateQueries({
            queryKey: [
              'thumbnail',
              'dialogueBox',
              assetDialogState.editingAssetId,
            ],
          });
        } else {
          const asset = await assetActions.addAsset('dialogueBox', data);

          onAdded?.(asset);
        }

        assetDialogState.editingAsset = undefined;
      } catch (err) {
        if (err instanceof ValidationError) {
          setFormErrors(err, setError);
        } else {
          setError('root', { message: getErrorMessage(err) });
        }
      }
    };

    const handleCancel = () => {
      assetDialogState.editingAsset = undefined;
    };

    return (
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Stack spacing={2}>
                {errors.root?.message && (
                  <Alert severity="error" variant="filled">
                    {errors.root.message}
                  </Alert>
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography variant="h6">
                    {assetDialogState.editingAssetId ? 'Edit' : 'Add'} Dialogue
                    Box
                  </Typography>

                  <PresetPicker setValue={setValue} trigger={trigger} />
                </Stack>

                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      autoFocus
                      fullWidth
                    />
                  )}
                />

                <Controller
                  name="url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Image URL"
                      error={!!errors.url}
                      helperText={errors.url?.message}
                      fullWidth
                    />
                  )}
                />

                <Box>
                  <DialogueBoxTextForm control={control} errors={errors} />

                  <DialogueBoxNameplateForm control={control} errors={errors} />

                  <DialogueBoxNextButtonForm
                    control={control}
                    setValue={setValue}
                    errors={errors}
                  />
                </Box>
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <DialogueBoxPreview control={control} />
            </Grid2>
          </Grid2>

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {assetDialogState.editingAssetId ? 'Edit' : 'Add'}
            </Button>

            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  },
);

type DialogueBoxFormProps = {
  control: Control<CreateDialogueBoxDto>;
  errors: FieldErrors<CreateDialogueBoxDto>;
};

const DialogueBoxNameplateForm = ({
  control,
  errors,
}: DialogueBoxFormProps) => {
  const hasNameplateError = !!errors.nameplate;

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Nameplate Properties {hasNameplateError && <ErrorIcon />}
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <Controller
            name="nameplate.url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Image URL"
                error={!!errors.nameplate?.url}
                helperText={errors.nameplate?.url?.message}
                variant="standard"
                fullWidth
              />
            )}
          />

          <Controller
            name="nameplate.fontUrl"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Font URL"
                error={!!errors.nameplate?.fontUrl}
                helperText={errors.nameplate?.fontUrl?.message}
                variant="standard"
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="nameplate.fontSize"
            render={({ field }) => (
              <SliderWithInput
                label="Font Size"
                value={field.value ?? 0}
                onChange={(value) => field.onChange(value)}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={0.1}
                size="small"
              />
            )}
          />

          <DualGridPicker>
            <Controller
              control={control}
              name="nameplate.top"
              render={({ field }) => (
                <SliderWithInput
                  label="Top Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="nameplate.right"
              render={({ field }) => (
                <SliderWithInput
                  label="Right Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />
          </DualGridPicker>

          <Controller
            control={control}
            name="nameplate.replaceBox"
            render={({ field }) => (
              <Checkbox
                label="Nameplate replaces dialogue box"
                value={!!field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const DialogueBoxTextForm = ({ control, errors }: DialogueBoxFormProps) => {
  const hasTextError = !!errors.text;

  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Text Properties {hasTextError && <ErrorIcon />}
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <Controller
            name="text.fontUrl"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Font URL"
                error={!!errors.text?.fontUrl}
                helperText={errors.text?.fontUrl?.message}
                variant="standard"
                fullWidth
              />
            )}
          />

          <DualGridPicker>
            <Controller
              control={control}
              name="text.fontSize"
              render={({ field }) => (
                <SliderWithInput
                  label="Font Size"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="text.lineHeight"
              render={({ field }) => (
                <SliderWithInput
                  label="Line Height"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                  step={0.05}
                  size="small"
                />
              )}
            />
          </DualGridPicker>

          <DualGridPicker>
            <Controller
              control={control}
              name="text.width"
              render={({ field }) => (
                <SliderWithInput
                  label="Width"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="text.height"
              render={({ field }) => (
                <SliderWithInput
                  label="Height"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />
          </DualGridPicker>

          <DualGridPicker>
            <Controller
              control={control}
              name="text.top"
              render={({ field }) => (
                <SliderWithInput
                  label="Top Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="text.left"
              render={({ field }) => (
                <SliderWithInput
                  label="Left Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />
          </DualGridPicker>

          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            <Controller
              control={control}
              name="defaultColor"
              render={({ field }) => (
                <ColorInput
                  label="Default Text"
                  value={field.value || DefaultDialogueTextColor}
                  onChange={field.onChange}
                  fallbackValue={DefaultDialogueTextColor}
                  sx={{ width: 150 }}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="red"
              render={({ field }) => (
                <ColorInput
                  label="Red Text"
                  value={field.value || DefaultDialogueRedTextColor}
                  onChange={field.onChange}
                  fallbackValue={DefaultDialogueRedTextColor}
                  sx={{ width: 150 }}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="green"
              render={({ field }) => (
                <ColorInput
                  label="Green Text"
                  value={field.value || DefaultDialogueGreenTextColor}
                  onChange={field.onChange}
                  fallbackValue={DefaultDialogueGreenTextColor}
                  sx={{ width: 150 }}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="blue"
              render={({ field }) => (
                <ColorInput
                  label="Blue Text"
                  value={field.value || DefaultDialogueBlueTextColor}
                  onChange={field.onChange}
                  fallbackValue={DefaultDialogueBlueTextColor}
                  sx={{ width: 150 }}
                  size="small"
                />
              )}
            />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const DialogueBoxNextButtonForm = ({
  control,
  errors,
  setValue,
}: DialogueBoxFormProps & {
  setValue: UseFormSetValue<CreateDialogueBoxDto>;
}) => {
  const icon = useWatch({ control, name: 'nextButton.icon' });

  const iconType = useMemo(() => getNextButtonType(icon), [icon]);

  const handleChangeIconType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'none') {
      setValue('nextButton.icon', 'none');
    } else if (event.target.value === 'icon') {
      setValue('nextButton.icon', 'playarrow');
    } else {
      setValue('nextButton.icon', 'next');
    }
  };

  const hasNextButtonError = !!errors.nextButton;

  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Next Button {hasNextButtonError && <ErrorIcon />}
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Type</FormLabel>

            <RadioGroup row value={iconType} onChange={handleChangeIconType}>
              <FormControlLabel value="icon" control={<Radio />} label="Icon" />

              <FormControlLabel value="text" control={<Radio />} label="Text" />

              <FormControlLabel
                value="none"
                control={<Radio />}
                label="Invisible"
              />
            </RadioGroup>
          </FormControl>

          <IconInput control={control} />

          <Controller
            name="nextButton.url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Image URL"
                error={!!errors.nextButton?.url}
                helperText={errors.nextButton?.url?.message}
                variant="standard"
                fullWidth
              />
            )}
          />

          <DualGridPicker>
            <Controller
              control={control}
              name="nextButton.iconSize"
              render={({ field }) => (
                <SliderWithInput
                  label="Icon Size"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={25}
                  step={0.25}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="nextButton.iconAnimDuration"
              render={({ field }) => (
                <SliderWithInput
                  label="Animation Duration"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={5}
                  step={0.25}
                  size="small"
                />
              )}
            />
          </DualGridPicker>

          <DualGridPicker>
            <Controller
              control={control}
              name="nextButton.top"
              render={({ field }) => (
                <SliderWithInput
                  label="Top Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="nextButton.left"
              render={({ field }) => (
                <SliderWithInput
                  label="Left Position"
                  value={field.value ?? 0}
                  onChange={(value) => field.onChange(value)}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={0.1}
                  size="small"
                />
              )}
            />
          </DualGridPicker>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const IconInput = ({ control }: { control: Control<CreateDialogueBoxDto> }) => {
  const icon = useWatch({ control, name: 'nextButton.icon' });
  const iconType = getNextButtonType(icon);

  return (
    <Controller
      control={control}
      name="nextButton.icon"
      render={({ field, fieldState }) => (
        <NextButtonIconInput
          value={field.value}
          onChange={field.onChange}
          type={iconType}
          error={fieldState.error?.message}
        />
      )}
    />
  );
};

const DialogueBoxPreview = ({
  control,
}: {
  control: Control<CreateDialogueBoxDto>;
}) => {
  const [textPreview, setTextPreview] = useState('This is sample text');
  const [nameplatePreview, setNameplatePreview] = useState('Phoenix');

  const [preview, setPreview] = useState(true);
  const [showNameplate, setShowNameplate] = useState(true);
  const [showNextButton, setShowNextButton] = useState(true);
  const [colorPreview, setColorPreview] = useState('d');

  const ref = useRef<HTMLDivElement>(null);

  const { aspectRatio } = useMakerStore();

  const {
    state: { size },
    actions: { setPlayerSize },
  } = usePlayerSize(ref, aspectRatio, 960);

  const [url, nameplate, text, nextButton, defaultColor, red, green, blue] =
    useWatch({
      control,
      name: [
        'url',
        'nameplate',
        'text',
        'nextButton',
        'defaultColor',
        'red',
        'green',
        'blue',
      ],
    });

  // get an id hash based on text fontUrl
  const id = useMemo(() => {
    if (!text.fontUrl) {
      return '0';
    }

    return md5(text.fontUrl).toString();
  }, [text.fontUrl]);

  const color = useMemo(() => {
    if (colorPreview === 'r') {
      return red ? `#${red}` : DefaultDialogueRedTextColor;
    } else if (colorPreview === 'g') {
      return green ? `#${green}` : DefaultDialogueGreenTextColor;
    } else if (colorPreview === 'b') {
      return blue ? `#${blue}` : DefaultDialogueBlueTextColor;
    } else {
      return defaultColor ? `#${defaultColor}` : DefaultDialogueTextColor;
    }
  }, [colorPreview, red, green, blue, defaultColor]);

  const { errors } = useFormState({ control });

  const previewBackground = usePreviewBackground();

  const dialogueBoxUrl =
    nameplate.replaceBox && showNameplate ? nameplate.url : url;

  useFont(`font_${id}`, text.fontUrl, true);
  useFont(`nameplate_font_${id}`, nameplate.fontUrl, true);

  const { containerStyle, textContainerStyle } = useDialogueBoxStyles({
    id: id,
    size,
    text,
    preview,
  });

  const { nameplateContainerStyle } = useNameplateStyles({
    ...nameplate,
    id,
    size,
    preview,
  });

  useEffect(() => {
    setPlayerSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text.fontUrl]);

  if (!url || errors.url || !previewBackground) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Container ref={ref} aspectRatio={aspectRatio}>
        <Background url={previewBackground.url} />
        <Desk url={previewBackground.deskUrl} />

        <DialogueBoxContainer style={containerStyle}>
          <DialogueBoxImage alt="Dialogue Box" src={dialogueBoxUrl} />

          <DialogueBoxTextContainer style={textContainerStyle} dir="auto">
            <Box component="span" style={{ color: color }}>
              {textPreview}
            </Box>
          </DialogueBoxTextContainer>

          <NextButton
            {...nextButton}
            id={id}
            size={size}
            preview={preview}
            show={showNextButton}
          />
        </DialogueBoxContainer>

        <NameplateContainer
          sx={{ display: !showNameplate ? 'none' : 'inherit' }}
        >
          <NameplateImage
            alt="Nameplate"
            src={nameplate.url}
            sx={{ visibility: nameplate.replaceBox ? 'hidden' : 'visible' }}
          />

          <NameplateTextContainer style={nameplateContainerStyle} dir="auto">
            {nameplatePreview}
          </NameplateTextContainer>
        </NameplateContainer>
      </Container>

      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 8 }}>
          <TextField
            label="Text Preview"
            value={textPreview}
            onChange={(e) => setTextPreview(e.target.value)}
            size="small"
            multiline
            fullWidth
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            label="Nameplate Preview"
            value={nameplatePreview}
            onChange={(e) => setNameplatePreview(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid2>
      </Grid2>

      <Stack
        direction="row"
        gap={1}
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Checkbox
          label="Show Boundaries"
          value={preview}
          onChange={setPreview}
          size="small"
        />

        <Checkbox
          label="Show Nameplate"
          value={showNameplate}
          onChange={setShowNameplate}
          size="small"
        />

        <Checkbox
          label="Show Next Button"
          value={showNextButton}
          onChange={setShowNextButton}
          size="small"
        />
      </Stack>

      <PreviewColorPicker
        colorPreview={colorPreview}
        setColorPreview={setColorPreview}
      />
    </Stack>
  );
};

const PreviewColorPicker = ({
  colorPreview,
  setColorPreview,
}: {
  colorPreview: string | undefined;
  setColorPreview: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <FormControl>
        <FormLabel>Color Preview</FormLabel>

        <RadioGroup
          row
          value={colorPreview}
          onChange={(e) => setColorPreview(e.target.value)}
        >
          <FormControlLabel value="d" control={<Radio />} label="Default" />

          <FormControlLabel value="r" control={<Radio />} label="Red" />

          <FormControlLabel value="g" control={<Radio />} label="Green" />

          <FormControlLabel value="b" control={<Radio />} label="Blue" />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

const PresetPicker = ({
  setValue,
  trigger,
}: {
  setValue: UseFormSetValue<CreateDialogueBoxDto>;
  trigger: UseFormTrigger<CreateDialogueBoxDto>;
}) => {
  const setFromPresetDialogueBox = useCallback(
    (index: number) => {
      // same as default values
      const preset = PresetDialogueBoxes[index];

      setValue('url', preset.url);
      setValue('defaultColor', preset.defaultColor);
      setValue('red', preset.red);
      setValue('green', preset.green);
      setValue('blue', preset.blue);

      setValue('nameplate', {
        url: preset.nameplate.url,
        fontSize: preset.nameplate.fontSize,
        fontUrl: preset.nameplate.fontUrl,
        replaceBox: preset.nameplate.replaceBox,
        top: preset.nameplate.top,
        right: preset.nameplate.right,
      });

      setValue('text', {
        fontUrl: preset.text.fontUrl,
        fontSize: preset.text.fontSize,
        width: preset.text.width,
        height: preset.text.height,
        lineHeight: preset.text.lineHeight || 0,
        top: preset.text.top,
        left: preset.text.left,
      });

      setValue('nextButton', {
        url: preset.nextButton.url,
        icon: preset.nextButton.icon,
        iconAnimDuration: preset.nextButton.iconAnimDuration,
        iconSize: preset.nextButton.iconSize,
        iconColor: preset.nextButton.iconColor,
        top: preset.nextButton.top,
        left: preset.nextButton.left,
      });

      trigger();
    },
    [setValue, trigger],
  );

  const handlePresetChange = useCallback(
    (event: SelectChangeEvent) => {
      setFromPresetDialogueBox(Number(event.target.value));
    },
    [setFromPresetDialogueBox],
  );

  return (
    <Select
      value=""
      displayEmpty
      onChange={handlePresetChange}
      renderValue={(selected) => <em>Apply Preset</em>}
      variant="standard"
      size="small"
      disableUnderline
    >
      <MenuItem disabled>
        <Typography variant="caption">
          Choosing an item will overwrite your dialogue box
        </Typography>
      </MenuItem>

      {PresetDialogueBoxes.map((preset, index) => (
        <MenuItem key={index} value={index}>
          {preset.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const ErrorIcon = () => <Error color="error" style={{ paddingLeft: 4 }} />;
