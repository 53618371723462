import { CaseFrame, Character_Side, Options, Pair } from '@shared/types';
import {
  BackgroundDto,
  EvidenceDto,
  MusicDto,
  PopupDto,
  SoundDto,
} from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import poseToMusicMap from '@web/data/audio/pose_to_music_map';
import frameActions from '@web/data/frame-actions';
import { assetActions } from '@web/store/assets/actions';
import { AssetType, assetStore } from '@web/store/assets/state';
import { Character, DialogueBox } from '@web/types/project';
import { frameUtils } from '@web/utils/frame';
import { isNumber } from 'lodash';

type AudioItem = {
  url: string;
  type: 'music' | 'sound' | 'blip';
};

type PreloadItemsData = {
  images: string[];
  audio: AudioItem[];
  fonts: string[];
};

export const PreloaderUtils = {
  async preloadFrames(
    frames: CaseFrame[],
    pairs: Pair[],
    projectOptions: Options,
    preloadProgressCallback?: (progress: number) => void,
    abortController?: AbortController,
    timeout?: number,
    timeoutCallback?: () => void,
  ) {
    await this.loadCustomAssets(frames, pairs, abortController);

    if (abortController?.signal.aborted) {
      return [];
    }

    const preloadItems = await this.getPreloadItems(
      frames,
      pairs,
      projectOptions,
    );

    const totalItems =
      preloadItems.images.length +
      preloadItems.audio.length +
      preloadItems.fonts.length;

    let loadedItems = 0;

    const promises = [
      ...preloadItems.images.map((src) =>
        this.preloadImage(src, abortController),
      ),
      ...preloadItems.audio.map((audioItem) =>
        this.preloadAudio(audioItem, abortController),
      ),
      ...preloadItems.fonts.map((src) =>
        this.preloadFont(src, abortController),
      ),
    ];

    try {
      await Promise.race([
        Promise.all(
          promises.map(async (promise) => {
            await promise;

            loadedItems++;

            if (preloadProgressCallback) {
              preloadProgressCallback((loadedItems / totalItems) * 100);
            }
          }),
        ),
        ...(timeout
          ? [preloadTimeout(timeout, 'Preloader timed out', timeoutCallback)]
          : []),
      ]);
    } catch (error) {
      console.error('Preloader failed to load assets', error);

      return [];
    }

    return preloadItems.images;
  },
  async loadCustomAssets(
    frames: CaseFrame[],
    pairs: Pair[],
    abortController?: AbortController,
  ) {
    try {
      return await Promise.all([
        this.loadCustomCharacters(frames, pairs, abortController),
        this.loadCustomBackgrounds(frames, abortController),
        this.loadCustomPopups(frames, abortController),
      ]);
    } catch (error) {
      console.error('Failed to load custom assets', error);
    }
  },
  async getPreloadItems(
    frames: CaseFrame[],
    pairs: Pair[],
    projectOptions: Options,
  ): Promise<PreloadItemsData> {
    const images = new Set<string>();
    const audio = new Map<string, AudioItem>();
    const fonts = new Set<string>();

    const addAudio = ({
      url,
      type,
    }: {
      url: string;
      type: AudioItem['type'];
    }) => {
      audio.set(url, { url, type });
    };

    for (const frame of frames) {
      const character = frameUtils.getCharacter(frame) as Character | undefined;
      const pose = frameUtils.getPose(frame);

      // paired character idle pose
      if (frame.pair?.id) {
        const projectPair = pairs.find((f) => f.id === frame.pair?.id);

        if (projectPair) {
          projectPair.pairs?.forEach((pair, index) => {
            if (!pair.characterId || pair?.characterId === character?.id)
              return;

            const otherCharacter = assetStore.character.cache[pair.characterId];
            const otherPose = otherCharacter?.poses.find(
              (p) => p.id === frame.pair?.poseIds[pair.characterId!],
            );

            if (otherPose) {
              images.add(otherPose.idleImageUrl);
            }
          });

          // const otherCharacterId = projectPair.pairs?.find(
          //   (p) => p.characterId !== character?.id,
          // )?.characterId;

          // const otherPose = assetStore.character.cache[
          //   otherCharacterId || 0
          // ].poses.find((p) => p.id === frame.pair?.pairPoseId);

          // if (otherPose) {
          //   images.add(otherPose.idleImageUrl);
          // }
        }
      }

      // speech blip sound
      addAudio({
        url: frameUtils.getSpeechBlipUrl(frame, character),
        type: 'blip',
      });

      // speech bubble
      if (frame.speechBubble) {
        const speechBubble = character?.speechBubbles.find(
          (sb) => sb.id === frame.speechBubble,
        );

        if (speechBubble) {
          images.add(speechBubble.imageUrl);

          addAudio({
            url: speechBubble.soundUrl || '/Audio/Vocal/sfx-objection.wav',
            type: 'sound',
          });
        }
      }

      // music
      if (frame.poseId && frame.text?.startsWith('[#bgmd]')) {
        const music = poseToMusicMap[frame.poseId]
          ? `/Audio/Music/${poseToMusicMap[frame.poseId]}.ogg`
          : `/Audio/Music/trial.ogg`;

        if (music) {
          addAudio({ url: music, type: 'music' });
        }
      } else {
        const musicTagId = FrameTextUtils.getFirstTagId(frame.text, 'bgm');

        if (musicTagId) {
          const music = await getAsset<MusicDto>('music', musicTagId);

          if (music) {
            addAudio({ url: music.url, type: 'music' });
          }
        }
      }

      // sounds
      const soundTags = FrameTextUtils.getUniqueSoundTags(frame.text).slice(
        0,
        6,
      );

      for (const soundTag of soundTags) {
        const soundId = parseInt(soundTag);
        const sound = await getAsset<SoundDto>('sound', soundId);

        if (sound) {
          addAudio({ url: sound.url, type: 'sound' });
        }
      }

      // fullscreen evidence
      const fullscreenEvidenceTag = FrameTextUtils.getFirstTagId(
        frame.text,
        'evd',
      );

      if (fullscreenEvidenceTag) {
        const evidence = await getAsset<EvidenceDto>(
          'evidence',
          fullscreenEvidenceTag,
        );

        if (evidence) {
          images.add(evidence.url);
        }
      }

      // icon evidence
      const iconEvidenceTag = FrameTextUtils.getFirstTagId(frame.text, 'evdi');

      if (iconEvidenceTag) {
        const evidence = await getAsset<EvidenceDto>(
          'evidence',
          iconEvidenceTag,
        );

        if (evidence) {
          images.add(evidence.url);
          images.add('/Images/evidence container.png');
          addAudio({ url: '/Audio/Sound/shooop.mp3', type: 'sound' });
        }
      }

      // background
      const backgroundId = frame.backgroundId || character?.backgroundId;

      if (backgroundId) {
        const background = await getAsset<BackgroundDto>(
          'background',
          backgroundId,
        );

        if (background) {
          images.add(background.url);

          if (background.deskUrl && background.deskUrl !== '*') {
            images.add(background.deskUrl);
          }
        }
      }

      // pose
      if (pose) {
        images.add(pose.idleImageUrl);

        if (pose.speakImageUrl) {
          images.add(pose.speakImageUrl);
        }

        if (pose.isSpeedlines) {
          images.add('/Images/speedlines.gif');
        }

        if (!frame.noPoseAnimation) {
          pose.poseStates.forEach((tick) => {
            images.add(tick.imageUrl);
          });

          pose.poseAudioTicks.forEach((tick) => {
            addAudio({ url: tick.fileName, type: 'sound' });
          });
        }
      }

      // custom popup
      if (frame.popupId) {
        const popup = await getAsset<PopupDto>('popup', frame.popupId);

        if (popup) {
          images.add(popup.url);
        }
      }

      // gallery
      if (character && character?.side !== Character_Side.Gallery) {
        images.add(character.galleryImageUrl);
        images.add(character.galleryAJImageUrl);
      }

      // frame actions
      if (frame.actions && frame.actions.length > 0) {
        for (const frameAction of frame.actions.slice(0, 10)) {
          const action = frameActions.find(
            (a) => a.id === frameAction.actionId,
          );

          if (action?.id === 2 || action?.id === 10) {
            // gallery sprite
            const galleryCharacter =
              assetStore.character.cache[frameAction.actionParam];

            if (galleryCharacter) {
              images.add(
                action.id === 2
                  ? galleryCharacter.galleryImageUrl
                  : galleryCharacter.galleryAJImageUrl,
              );
            }
          }

          if (action?.options && frameAction.actionParam !== '0') {
            const option = action.options.find(
              (o) => o.value === frameAction.actionParam,
            );

            option?.states?.forEach((state) => {
              if (state.type === 'image' && state.image) {
                images.add(state.image);
              }

              if (state.type === 'sound' && state.sound) {
                addAudio({ url: state.sound, type: 'sound' });
              }
            });
          }

          // change dialogue box
          if (action?.id === 12 && !isNumber(frameAction.actionParam)) {
            await getAsset<DialogueBox>('dialogueBox', frameAction.actionParam);
          }
        }
      }

      // project options
      if (projectOptions.continueSoundUrl) {
        addAudio({ url: projectOptions.continueSoundUrl, type: 'sound' });
      }

      if (projectOptions.chatbox && projectOptions.chatbox !== '0') {
        const chatbox = await getAsset<DialogueBox>(
          'dialogueBox',
          projectOptions.chatbox,
        );

        if (chatbox) {
          images.add(chatbox.url);

          if (chatbox.nameplate) {
            images.add(chatbox.nameplate.url);
            fonts.add(chatbox.nameplate.fontUrl);
          }

          if (chatbox.text) {
            fonts.add(chatbox.text.fontUrl);
          }
        }
      }
    }

    return {
      images: Array.from(images).filter((f) => f),
      audio: Array.from(audio.values()),
      fonts: Array.from(fonts).filter((f) => f),
    };
  },
  async loadCustomCharacters(
    frames: CaseFrame[],
    pairs: Pair[],
    abortController?: AbortController,
  ) {
    const ids = new Set<number>(
      frames.filter((f) => f.characterId).map((f) => f.characterId!),
    );

    pairs.forEach((pair) => {
      pair.pairs?.forEach((p) => {
        if (p.characterId) {
          ids.add(p.characterId);
        }
      });
    });

    if (ids.size === 0) {
      return;
    }

    await assetActions.getAllAssets(
      'character',
      Array.from(ids),
      false,
      abortController,
    );
  },
  async loadCustomBackgrounds(
    frames: CaseFrame[],
    abortController?: AbortController,
  ) {
    const ids = new Set<number>(
      frames.filter((f) => f.backgroundId).map((f) => f.backgroundId!),
    );

    if (ids.size === 0) {
      return;
    }

    await assetActions.getAllAssets(
      'background',
      Array.from(ids),
      false,
      abortController,
    );
  },
  async loadCustomPopups(
    frames: CaseFrame[],
    abortController?: AbortController,
  ) {
    const ids = new Set<number>(
      frames.filter((f) => f.popupId).map((f) => f.popupId!),
    );

    if (ids.size === 0) {
      return;
    }

    await assetActions.getAllAssets(
      'popup',
      Array.from(ids),
      false,
      abortController,
    );
  },
  preloadImage(src: string, abortController?: AbortController) {
    return new Promise<void>((resolve) => {
      const img = new Image();

      img.onload = () => {
        resolve();
      };

      img.onerror = () => {
        console.error(`Preloader failed to load image: ${src}`);
        resolve();
      };

      if (abortController) {
        abortController.signal.addEventListener('abort', () => {
          img.onerror = null;
          img.src = '';

          resolve();
        });
      }

      img.src = src;
    });
  },
  preloadAudio(audioItem: AudioItem, abortController?: AbortController) {
    return new Promise<void>(function (resolve) {
      const audio = new Howl({
        src: [audioItem.url],
        loop: false,
        html5: !audioItem.url.startsWith('/') || audioItem.type === 'blip',
        onload: function () {
          // audio.unload();    // TODO: this was commented because it was causing the audio to load again when its supposed to be played

          resolve();
        },
        onloaderror: function () {
          audio.unload();

          console.error(
            `Preloader failed to load audio file: ${audioItem.url}`,
          );

          resolve();
        },
      });

      if (abortController) {
        abortController.signal.addEventListener('abort', () => {
          audio.unload();

          resolve();
        });
      }
    });
  },
  preloadFont(src: string, abortController?: AbortController) {
    return new Promise<void>(function (resolve) {
      const font = new FontFace('', `url(${src})`);

      font
        .load()
        .then(() => resolve())
        .catch(() => resolve());

      if (abortController) {
        abortController.signal.addEventListener('abort', () => {
          resolve();
        });
      }
    });
  },
};

const getAsset = async <T>(type: AssetType, id: number | string) => {
  let asset = assetStore[type].cache[id];

  if (!asset) {
    try {
      // @ts-expect-error id is number or string
      asset = (await ApiClient.assets[type].get(id))?.data;

      if (asset) {
        assetStore[type].cache[id] = asset;
      }
    } catch (error) {
      console.error('Failed to load asset', type, id);
    }
  }

  return asset as T | undefined;
};

const preloadTimeout = (
  ms: number,
  message: string,
  timeoutCallback: (() => void) | undefined,
) => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      if (timeoutCallback) {
        timeoutCallback();
      }

      reject(new Error(message));
    }, ms);
  });
};
