import { useEffect } from 'react';

const addedFonts = new Set<string>();

export const useFont = (name: string, url: string, cleanup?: boolean) => {
  useEffect(() => {
    if (!name || !url || addedFonts.has(name)) return;

    const style = document.createElement('style');

    style.innerHTML = `
      @font-face {
        font-family: '${name}';
        src: url('${url}');
      }
    `;

    document.head.appendChild(style);
    addedFonts.add(name);

    return () => {
      if (cleanup) {
        document.head.removeChild(style);
        addedFonts.delete(name);
      }
    };
  }, [name, url, cleanup]);
};
