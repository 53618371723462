import { Autocomplete, TextFieldVariants } from '@mui/material';
import { CreateCharacterDto, UpdateCharacterDto } from '@web/api/api';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { presetSpeechBlips } from '@web/data/preset/preset-speech-blips';
import { useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';

export const SpeechBlipInput = ({
  variant,
  control,
}: {
  variant?: TextFieldVariants | undefined;
  control: Control<CreateCharacterDto | UpdateCharacterDto> | undefined;
}) => {
  const {
    errors: { blipUrl: blipUrlError },
  } = useFormState({ control });
  const blipUrl = useWatch({ control, name: 'blipUrl' });

  const selectedValue = useMemo(
    () =>
      presetSpeechBlips.find((f) => f.url === blipUrl)?.url || blipUrl || null,
    [blipUrl],
  );

  return (
    <Controller
      control={control}
      name="blipUrl"
      render={({ field }) => (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            if (typeof newValue === 'object' && newValue !== null) {
              field.onChange(newValue.url);
            }
          }}
          onInputChange={(event, newInputValue) => {
            field.onChange(newInputValue);
          }}
          options={presetSpeechBlips}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              variant={variant ?? 'outlined'}
              error={!!blipUrlError}
              helperText={blipUrlError?.message}
              label="Speech Blip URL"
            />
          )}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option.label || '';
          }}
          getOptionKey={(option) => {
            if (typeof option === 'string') return option;
            return option.url;
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') return false;
            return option.url === value.url;
          }}
          freeSolo
        />
      )}
    />
  );
};
