import { CharacterDto, DialogueBoxDto } from '@web/api/api';
import { AssetDto, CreateAssetDto, UpdateAssetDto } from '@web/store/assets';
import { createRef } from 'react';
import { proxy, ref } from 'valtio';

export const assetDialogState = proxy({
  editingAsset: undefined as
    | CreateAssetDto
    | UpdateAssetDto
    | CharacterDto
    | DialogueBoxDto
    | undefined,
  editingAssetId: undefined as string | number | undefined,
  addedAsset: undefined as AssetDto | undefined,
  selectedIds: [] as (string | number)[],
  search: '',
  scrollRef: ref(createRef<HTMLDivElement>()),
});
