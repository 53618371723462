import { rootStore } from '@web/store/root/state';
import { useEffect } from 'react';

export const useBetaDialog = () => {
  useEffect(() => {
    if (localStorage.getItem('beta') !== 'true' && !rootStore.dialogs.beta) {
      rootStore.dialogs.beta = true;
    }
  }, []);
};
