import {
  ArrowBack,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  ChevronLeft,
  ChevronRight,
  DoubleArrow,
  Forward,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowUp,
  PlayArrow,
} from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { ReactNode } from 'react';

type IconProps = {
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
};

export const NextButtonIcons: Record<string, (props: IconProps) => ReactNode> =
  {
    playarrow: (props) => <PlayArrow {...props} />,
    chevronright: (props) => <ChevronRight {...props} />,
    chevronleft: (props) => <ChevronLeft {...props} />,
    arrowright: (props) => <ArrowRight {...props} />,
    arrowleft: (props) => <ArrowLeft {...props} />,
    arrowdropup: (props) => <ArrowDropUp {...props} />,
    arrowdropdown: (props) => <ArrowDropDown {...props} />,
    doublearrow: (props) => <DoubleArrow {...props} />,
    keyboarddoublearrowright: (props) => (
      <KeyboardDoubleArrowRight {...props} />
    ),
    keyboarddoublearrowleft: (props) => <KeyboardDoubleArrowLeft {...props} />,
    keyboarddoublearrowup: (props) => <KeyboardDoubleArrowUp {...props} />,
    keyboarddoublearrowdown: (props) => <KeyboardDoubleArrowDown {...props} />,
    arrowforward: (props) => <ArrowForward {...props} />,
    arrowback: (props) => <ArrowBack {...props} />,
    arrowupward: (props) => <ArrowUpward {...props} />,
    arrowdownward: (props) => <ArrowDownward {...props} />,
    arrowcircleright: (props) => <ArrowCircleRight {...props} />,
    arrowcircleleft: (props) => <ArrowCircleLeft {...props} />,
    forward: (props) => <Forward {...props} />,
  };
