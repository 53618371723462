import { presetDialogueBoxesOptions } from './preset/preset-dialogue-boxes';

export default Object.freeze<FrameActionItem[]>([
  {
    id: 1,
    text: 'Dialogue box: Toggle visiblity',
    hint: '',
    inputType: 'select',
    options: [
      {
        text: 'Hide',
        value: '0',
        hint: 'The dialogue box will hide until a character speaks',
      },
      {
        text: 'Show',
        value: '1',
        hint: "Typically you don't need to use this action. This will not work with a pose animation",
      },
    ],
    runAtStart: true,
  },
  {
    id: 2,
    text: 'Gallery: Assign a character',
    inputType: 'galleryCharacter',
    hint: 'Phoenix Wright gallery',
    options: [],
    runAtStart: true,
  },
  {
    id: 3,
    text: 'Gallery: Remove a character',
    hint: '',
    inputType: 'select',
    options: [
      {
        text: 'Defense Counsel',
        value: 'counsel',
      },
      {
        text: 'Attorney',
        value: 'defense',
      },
      {
        text: 'Prosecution',
        value: 'prosecution',
      },
      {
        text: 'Witness',
        value: 'witness',
      },
      {
        text: 'Judge',
        value: 'judge',
      },
    ],
    runAtStart: true,
  },
  {
    id: 4,
    text: 'Speech: Set the speech blip sound for this frame',
    hint: '',
    inputType: 'select',
    hide: true,
    options: [
      {
        text: 'Male',
        value: '1',
      },
      {
        text: 'Female',
        value: '2',
      },
      {
        text: 'Typewriter',
        value: '3',
      },
      {
        text: 'Echoing Blip',
        value: '4',
      },
      {
        text: 'Katonk',
        value: '5',
      },
    ],
    runAtStart: true,
  },
  {
    id: 5,
    text: 'Speech: Mute the speech blip for this frame',
    hint: '',
    inputType: '',
    hide: true,
    options: [],
    runAtStart: true,
  },
  {
    id: 6,
    text: 'Off-screen frame',
    hint: 'The frame will not show, except for speech bubble',
    inputType: '',
    options: [],
    runAtStart: true,
  },
  {
    id: 7,
    text: 'Popup: Display a popup',
    hint: '',
    inputType: 'select',
    options: [
      {
        text: 'Witness Testimony',
        value: '1',
        states: [
          {
            ttl: 2730,
            type: 'image',
            image: '/Images/Popups/wt.gif',
          },
          {
            time: 50,
            type: 'sound',
            sound: '/Audio/testimony-start.wav',
            volume: 0.55 * 100,
          },
          {
            time: 2100,
            type: 'sound',
            sound: '/Audio/testimony-end.wav',
            volume: 0.55 * 100,
          },
        ],
      },
      {
        text: 'Cross Examination',
        value: '2',
        states: [
          {
            ttl: 2930,
            type: 'image',
            image: '/Images/Popups/ce.gif',
          },
          {
            time: 50,
            type: 'sound',
            sound: '/Audio/testimony-start.wav',
            volume: 0.55 * 100,
          },
          {
            time: 2500,
            type: 'sound',
            sound: '/Audio/testimony-end.wav',
            volume: 0.55 * 100,
          },
        ],
      },
      {
        text: 'Testimony Label',
        value: '3',
        states: [
          {
            ttl: 0,
            type: 'image',
            image: '/Images/Popups/testimony.gif',
          },
        ],
      },
      {
        text: 'Guilty',
        value: '4',
        states: [
          {
            ttl: 3000,
            type: 'image',
            image: '/Images/Popups/guilty.gif',
          },
          {
            time: 467,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 667,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 968,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 1268,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 1568,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 1900,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
        ],
      },
      {
        text: 'Not Guilty',
        value: '5',
        states: [
          {
            ttl: 3000,
            type: 'image',
            image: '/Images/Popups/not guilty.gif',
          },
          {
            time: 600,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
          {
            time: 1600,
            type: 'sound',
            sound: '/Audio/Sound/guilty.mp3',
            volume: 0.75 * 100,
          },
        ],
      },
    ],
    runAtStart: false,
  },
  {
    id: 8,
    text: 'Popup: Remove a popup',
    hint: '',
    inputType: 'select',
    options: [
      {
        text: 'Testimony Label',
        value: '1',
      },
    ],
    runAtStart: false,
  },
  {
    id: 9,
    text: 'Text: Center the text for this frame',
    hint: '',
    inputType: '',
    options: [],
    runAtStart: true,
  },
  {
    id: 10,
    text: 'Gallery: Assign a character (AJ)',
    hint: 'Apollo Justice gallery',
    inputType: 'galleryCharacterAj',
    options: [],
    runAtStart: true,
  },
  {
    id: 11,
    text: 'Gallery: Remove a character (AJ)',
    hint: '',
    inputType: 'select',
    options: [
      {
        text: 'Defense Counsel',
        value: 'counsel',
      },
      {
        text: 'Attorney',
        value: 'defense',
      },
      {
        text: 'Prosecution',
        value: 'prosecution',
      },
      {
        text: 'Witness',
        value: 'witness',
      },
      {
        text: 'Judge',
        value: 'judge',
      },
    ],
    runAtStart: true,
  },
  {
    id: 12,
    text: 'Dialogue box: Change style',
    hint: '',
    inputType: 'dialogueBox',
    defaultValue: '0',
    options: [...presetDialogueBoxesOptions],
    runAtStart: true,
  },
  {
    id: 13,
    text: 'Text: Change the default text speed',
    hint: 'Default: 35',
    inputType: 'number',
    defaultValue: 35,
    options: [],
    runAtStart: true,
  },
  {
    id: 14,
    text: 'Text: Change the blip frequency value',
    hint: 'Controls how often the speech blip sound is played, minimum value of 25',
    inputType: 'number',
    defaultValue: 64,
    options: [],
    runAtStart: true,
  },
  {
    id: 15,
    text: 'Change the autoplay speed',
    hint: 'Adjusts frame delay for auto-play or recording. Default: 500ms',
    inputType: 'number',
    defaultValue: 500,
    options: [],
    runAtStart: true,
  },
  {
    id: 16,
    text: 'Toggle frame skipping',
    hint: 'disable or enable the click to skip functionality',
    inputType: 'select',
    options: [
      {
        text: 'Disabled',
        value: '0',
      },
      {
        text: 'Enabled',
        value: '1',
      },
    ],
    runAtStart: true,
  },
]);

export interface FrameActionOptionState {
  ttl?: number;
  time?: number;
  type: 'image' | 'sound';
  image?: string;
  sound?: string;
  volume?: number;
}

export interface FrameActionOption {
  text: string;
  value: string;
  hint?: string;
  states?: FrameActionOptionState[];
}

export interface FrameActionItem {
  id: number;
  text: string;
  hint?: string;
  inputType: string;
  options: FrameActionOption[];
  runAtStart: boolean;
  hide?: boolean;
  defaultValue?: string | number;
}
