import { Edit } from '@mui/icons-material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import { MusicDto } from '@web/api/api';
import { useAssetStore } from '@web/store/assets/state';
import { memo, useMemo } from 'react';
import { AssetsDataGrid } from '../AssetsDataGrid';
import { AudioPreviewButton } from '../ui/AudioPreviewButton';

type MusicAssetsProps = {
  onSelectChange?: (selection: (string | number)[]) => void;
  onAction?: (action: 'edit', id: number) => void;
} & Partial<DataGridProps>;

export const DataGridMusic = memo(
  ({ onSelectChange, onAction, ...props }: MusicAssetsProps) => {
    const {
      music: { user },
    } = useAssetStore();

    const rows = useMemo(() => [...user], [user]);

    const columns = useMemo<GridColDef<MusicDto>[]>(
      () => [
        { field: 'id', headerName: 'ID', width: 100, hideSortIcons: false },
        {
          field: 'url',
          headerName: 'Music',
          renderCell: (params) => <RenderPreviewCell music={params.row} />,
          width: 120,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 240,
        },
        {
          field: 'volume',
          headerName: 'Volume',
          width: 80,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                onAction?.('edit', params.id as number);
              }}
            />,
          ],
        },
      ],
      [onAction],
    );

    return (
      <AssetsDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={66.67}
        onSelectChange={onSelectChange}
      />
    );
  },
);

const RenderPreviewCell = ({ music }: { music: MusicDto }) => {
  return (
    <AudioPreviewButton
      url={music.url}
      volume={music.volume}
      variant="text"
      size="small"
    />
  );
};
