import { PopupDto } from '@web/api/api';
import { useEffect, useState } from 'react';
import { usePlayerResetRegister } from './hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './hooks/usePlayerSaveLoadRegister';
import { useShake } from './hooks/useShake';
import { usePlayer } from './providers/PlayerProvider';
import { Popup, PopupProps } from './ui/Popup';

export const PlayerPopup = () => {
  const {
    step,
    frame: { popup: framePopup },
  } = usePlayer();

  const [popup, setPopup] = useState<PopupDto | undefined>(undefined);

  const shakeSx = useShake();

  usePlayerSaveLoadRegister({
    name: 'popup',
    onSave: () => popup,
    onLoad: (data) => setPopup(data),
  });

  usePlayerResetRegister({
    name: 'popup',
    onReset: () => setPopup(undefined),
  });

  useEffect(() => {
    if (step !== 'character') {
      return;
    }

    setPopup(framePopup || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (!popup) {
    return null;
  }

  return (
    <Popup
      url={popup.url}
      size={popup.resize}
      x={popup.posX}
      y={popup.posY}
      center={popup.center}
      sx={shakeSx}
    />
  );
};

export const PlayerPresetPopups = () => {
  const {
    state: { presetPopupsImageUrls },
  } = usePlayer();

  if (!presetPopupsImageUrls || !presetPopupsImageUrls.length) {
    return null;
  }

  return (
    <>
      {presetPopupsImageUrls.map((url, index) => (
        <PopupWithRefresh
          key={index}
          url={url}
          size={100}
          x={0}
          y={0}
          center={true}
        />
      ))}
    </>
  );
};

const PopupWithRefresh = ({ ...props }: PopupProps) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    setImageUrl(
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    );

    requestAnimationFrame(() => {
      setImageUrl(props?.url ?? undefined);
    });
  }, [props.url]);

  if (!imageUrl) {
    return null;
  }

  return <Popup {...props} url={imageUrl} />;
};
