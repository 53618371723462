import { MuiColorInput, MuiColorInputProps } from 'mui-color-input';

type ColorInputProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
} & Omit<MuiColorInputProps, 'value' | 'onChange'>;

export const ColorInput = ({ value, onChange, ...props }: ColorInputProps) => {
  const valueWithSymbol = value.includes('#') ? value : `#${value}`;

  const handleChange = (value: string) => {
    onChange(value.replace('#', ''));
  };

  return (
    <MuiColorInput
      value={valueWithSymbol}
      onChange={handleChange}
      format="hex"
      isAlphaHidden
      {...props}
    />
  );
};
