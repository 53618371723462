import { Box } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { AssetDto } from '@web/store/assets';
import { useCallback, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { assetDialogState } from './dialogs/AssetsDialogState';

export type AssetsDataGridProps = {
  onSelectChange?: (selection: (string | number)[]) => void;
  onAction?: (action: 'edit', id: number) => void;
  rows: AssetDto[];
} & Omit<DataGridProps, 'rows'>;

export const AssetsDataGrid = (props: AssetsDataGridProps) => {
  const { search } = useSnapshot(assetDialogState);

  const { onSelectChange, initialState, rows, ...other } = props;
  const gridRef = useGridApiRef();

  const handleSelectionChange = useCallback(
    (selection: GridRowSelectionModel) => {
      onSelectChange?.(selection.map((s) => s));
    },
    [onSelectChange],
  );

  const data = useMemo(
    () =>
      search
        ? rows?.filter((f) =>
            f.name?.toLowerCase().includes(search.toLowerCase()),
          )
        : rows,
    [rows, search],
  );

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid
        apiRef={gridRef}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          ...initialState,
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            visibility: 'hidden',
          },
        }}
        rows={data}
        onRowSelectionModelChange={handleSelectionChange}
        checkboxSelection
        disableColumnResize
        disableVirtualization
        disableColumnMenu
        disableRowSelectionOnClick
        {...other}
      />
    </Box>
  );
};
