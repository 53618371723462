/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

export const getErrorMessage = (error: any) => {
  if (error instanceof ValidationError) {
    const firstError = Object.values(error.validationErrors)[0];

    return firstError ?? 'Validation error.';
  }

  if (error.message === 'Network Error') {
    return 'Unable to connect to the server. Please check your internet connection.';
  }

  const statusCode = error.response?.status;

  switch (statusCode) {
    case 401:
    case 403:
      return 'You are not authorized to perform this action.';
    case 404:
      return 'The requested resource was not found.';
    case 429:
      return 'Too many requests. Please try again later.';
    case 503:
      return 'Service unavailable. Please try again later.';
  }

  if (error.response?.data?.message) {
    if (Array.isArray(error.response.data.message)) {
      return error.response.data.message[0];
    }

    return error.response.data.message;
  }

  return 'Something went wrong.';
};

type ValidationErrorResponse = Record<string, any>;

export class ValidationError extends AxiosError {
  public readonly validationErrors: Record<string, string>;

  constructor(axiosError: AxiosError<ValidationErrorResponse>) {
    super(
      axiosError.message,
      axiosError.code,
      axiosError.config,
      axiosError.request,
      axiosError.response,
    );

    this.name = 'ValidationError';

    this.validationErrors = this.flattenErrors(axiosError.response?.data ?? {});

    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  private flattenErrors(
    obj: Record<string, any>,
    parentKey = '',
  ): Record<string, string> {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
          acc[newKey] = value[0];
        } else if (typeof value === 'object' && value !== null) {
          Object.assign(acc, this.flattenErrors(value, newKey));
        } else {
          acc[newKey] = value;
        }

        return acc;
      },
      {} as Record<string, string>,
    );
  }
}
