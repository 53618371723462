import { SettingsStoreType, useSettingsStore } from '@web/store/settings';
import { WithIdAndName } from '@web/types';
import { useMemo } from 'react';

type useAssetOptionsProps<T> = {
  value: string | number | null;
  filter?: 'preset' | 'custom';
  customFilter?: (option: T) => boolean;
  type: keyof SettingsStoreType['favorite'];
  presetList: T[];
  userList: T[];
  allowNull?: boolean;
  filterFunction?: (option: T) => boolean;
};

export const useAssetOptions = <T extends WithIdAndName>({
  value,
  filter,
  customFilter,
  type,
  presetList,
  userList,
  allowNull,
  filterFunction,
}: useAssetOptionsProps<T>) => {
  const {
    favorite: { [type]: favoriteList },
  } = useSettingsStore();

  const options = useMemo<T[]>(() => {
    const combinedOptions =
      filter === 'preset'
        ? [...presetList]
        : filter === 'custom'
          ? [...userList]
          : [...presetList, ...userList];

    const valueOption =
      presetList.find((item) => item.id === value) ||
      userList.find((item) => item.id === value);

    if (
      valueOption &&
      !combinedOptions.some((item) => item.id === valueOption.id)
    ) {
      combinedOptions.push(valueOption);
    }

    return combinedOptions;
  }, [presetList, userList, filter, value]);

  const sortedOptions = useMemo(() => {
    const favorites = options
      .filter(
        (option) => option.id === null || favoriteList.includes(option.id),
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    const rest = options
      .filter(
        (option) => option.id !== null && !favoriteList.includes(option.id),
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    let res = [...favorites, ...rest];

    if (filterFunction) {
      return res.filter(filterFunction);
    }

    if (allowNull) {
      res = [{ id: null, name: 'None' } as T, ...res];
    }

    if (customFilter) {
      return res.filter(customFilter);
    }

    return res;
  }, [options, filterFunction, allowNull, customFilter, favoriteList]);

  const selectedValue = useMemo(() => {
    return options.find((option) => option.id === value) || null;
  }, [options, value]);

  return { sortedOptions, selectedValue };
};
