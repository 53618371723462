import { styled, SxProps, Theme } from '@mui/material';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type SpeechBubbleProps = {
  url: string;
  sx?: SxProps<Theme>;
  fullscreen?: boolean;
};

export const SpeechBubble = ({
  url,
  fullscreen,
  ...props
}: SpeechBubbleProps) => {
  // TODO: match width/height with existing old project (character isCustom or not) ?
  return (
    <SpeechBubbleStyled
      src={url}
      alt="Speech bubble"
      {...props}
      style={{
        width: fullscreen ? '100%' : '75%',
        height: fullscreen ? '100%' : 'auto',
        maxHeight: '100%',
      }}
    />
  );
};

const SpeechBubbleStyled = styled(Image)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: ZIndex.SpeechBubble,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
