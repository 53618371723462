import { Background } from '@web/components/player/ui/Background';
import { Container } from '@web/components/player/ui/Container';
import { Desk } from '@web/components/player/ui/Desk';
import {
  DialogueBoxContainer,
  DialogueBoxImage,
} from '@web/components/player/ui/Dialogue';
import { usePreviewBackground } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { DialogueBox } from '@web/types/project';
import { CSSProperties, memo, useMemo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';

export const DialogueBoxTabMobile = () => {
  return (
    <AssetTabMobile type="dialogueBox" PreviewComponent={PreviewDialogueBox} />
  );
};

const PreviewDialogueBox = memo(({ value }: { value: DialogueBox | null }) => {
  const previewBackground = usePreviewBackground();

  const { url, opacity, imageOpacity } = value || {};
  const { aspectRatio } = useMakerStore();

  const containerStyle = useMemo<CSSProperties>(
    () => ({
      opacity,
    }),
    [opacity],
  );

  if (!previewBackground || !value) {
    return null;
  }

  return (
    <Container
      aspectRatio={aspectRatio}
      sx={{ backgroundColor: 'transparent' }}
    >
      <Background
        url={previewBackground.url}
        isWide={previewBackground.isWide}
      />
      <Desk url={previewBackground.deskUrl} isWide={previewBackground.isWide} />

      <DialogueBoxContainer style={containerStyle}>
        <DialogueBoxImage
          alt="Dialogue Box"
          src={url!}
          style={{
            opacity: imageOpacity,
          }}
        />
      </DialogueBoxContainer>
    </Container>
  );
});
