import { DialogueBox } from '@web/types/project';
import { useNameplateStyles } from '../hooks/useDialogueBoxStyles';
import { usePlayerDialogueBoxContext } from '../providers/PlayerDialogueBoxContext';
import { usePlayer } from '../providers/PlayerProvider';
import {
  NameplateContainer,
  NameplateImage,
  NameplateTextContainer,
} from '../ui/Nameplate';

type NameplateProps = {
  id: string;
} & DialogueBox['nameplate'];

export const Nameplate = ({
  id,
  fontSize,
  url,
  replaceBox,
  top,
  right,
  letterSpacing,
  style,
}: NameplateProps) => {
  const {
    playerUi: {
      state: { size },
    },
  } = usePlayer();

  const { nameplate, showNameplate } = usePlayerDialogueBoxContext();

  const { nameplateContainerStyle } = useNameplateStyles({
    id,
    fontSize,
    top,
    right,
    size,
    letterSpacing,
    style,
  });

  return (
    <NameplateContainer sx={{ display: !showNameplate ? 'none' : 'inherit' }}>
      <NameplateImage
        alt="Nameplate"
        src={url}
        sx={{ visibility: replaceBox ? 'hidden' : 'visible' }}
      />

      <NameplateTextContainer style={nameplateContainerStyle} dir="auto">
        {nameplate}
      </NameplateTextContainer>
    </NameplateContainer>
  );
};
