import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreateEvidenceDto,
  EvidenceDto,
  UpdateEvidenceDto,
} from '@web/api/api';
import { Background } from '@web/components/player/ui/Background';
import { Evidence } from '@web/components/player/ui/Evidence';
import { assetActions } from '@web/store/assets/actions';
import { usePreviewBackground } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { urlValidation } from '@web/utils/yup';
import { memo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Form from '../../common/form/Form';
import { DualGridPicker } from '../../maker/ui/DualGridPicker';
import { Container } from '../../player/ui/Container';
import { Desk } from '../../player/ui/Desk';
import { assetDialogState } from '../dialogs/AssetsDialogState';

export const EvidenceForm = memo(
  ({ onAdded }: { onAdded?: (data: EvidenceDto) => void }) => {
    const schema = yup.object().shape({
      id: yup.number().optional(),
      name: yup.string().required(),
      url: urlValidation().required(),
      isIcon: yup.boolean().required(),
      deskUrl: urlValidation().optional().nullable(),
      isWide: yup.boolean().optional(),
    });

    const queryClient = useQueryClient();

    const onSubmit = async (data: CreateEvidenceDto | UpdateEvidenceDto) => {
      if (assetDialogState.editingAssetId) {
        await assetActions.updateAsset(
          'evidence',
          assetDialogState.editingAssetId,
          data,
        );

        queryClient.invalidateQueries({
          queryKey: ['icon', 'evidence', assetDialogState.editingAssetId],
        });
      } else {
        const asset = await assetActions.addAsset('evidence', data);

        onAdded?.(asset);
      }

      assetDialogState.editingAsset = undefined;
    };

    const handleCancel = () => {
      assetDialogState.editingAsset = undefined;
    };

    return (
      <Form
        schema={schema}
        onSubmit={onSubmit}
        defaultValues={
          assetDialogState.editingAssetId
            ? assetDialogState.editingAsset
            : { isIcon: false }
        }
        mode="onChange"
      >
        {({ control, register, errors, loading }) => (
          <Stack spacing={2}>
            <Typography variant="h6">
              {assetDialogState.editingAssetId ? 'Edit' : 'Add'} Evidence
            </Typography>

            <DualGridPicker>
              <Stack spacing={2}>
                {errors.root?.message && (
                  <Alert severity="error" variant="filled">
                    {errors.root.message}
                  </Alert>
                )}
                <TextField
                  label="Name"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  autoFocus
                  fullWidth
                />

                <TextField
                  label="Image URL"
                  {...register('url')}
                  error={!!errors.url}
                  helperText={errors.url?.message}
                  fullWidth
                />

                <Controller
                  control={control}
                  name="isIcon"
                  render={({ field }) => (
                    <Box>
                      <FormControlLabel
                        label="Is Icon"
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            checked={field.value ?? false}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                      />
                    </Box>
                  )}
                />
              </Stack>

              <EvidencePreview control={control} />
            </DualGridPicker>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {assetDialogState.editingAssetId ? 'Edit' : 'Add'}
              </Button>

              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Form>
    );
  },
);

const EvidencePreview = ({
  control,
}: {
  control: Control<CreateEvidenceDto | UpdateEvidenceDto> | undefined;
}) => {
  const { aspectRatio } = useMakerStore();

  const [url, isIcon] = useWatch({ control, name: ['url', 'isIcon'] });
  const { errors } = useFormState({ control });

  const previewBackground = usePreviewBackground();

  if (!url || errors.url || !previewBackground) {
    return null;
  }

  return (
    <Container aspectRatio={aspectRatio}>
      <Background url={previewBackground.url} />
      <Desk url={previewBackground.deskUrl} />

      <Evidence url={url} isIcon={isIcon} size={0.6} />
    </Container>
  );
};
