import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { ZIndex } from '@web/components/player/utils/constants';
import { useEffect, useRef, useState } from 'react';
import { continueAnimationClassic } from '../../hooks/useDialogueStyles';
import { useNextButton } from '../../hooks/useNextButton';
import { usePlayerDialogueBoxContext } from '../../providers/PlayerDialogueBoxContext';
import { usePlayer } from '../../providers/PlayerProvider';
import { DialogueBoxText } from '../DialogueBoxText';

// this dialogue box is unique and does not share functionality with the other dialogue boxes
export const ClassicDialogueBox = () => {
  const defaultFontSize = 35.25;

  const {
    playerUi: {
      state: { size },
    },
    playerDefaults: { centerText },
  } = usePlayer();

  const dialogue = usePlayerDialogueBoxContext();
  const textFitSizeRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);

  const fitFontSize = useRef(defaultFontSize);
  const [fontSize, setFontSize] = useState(defaultFontSize);

  // const shakeSx = useShake();

  const findFitTextSize = () => {
    if (!textFitSizeRef.current) {
      return;
    }

    const resizedFontSize = size * defaultFontSize;

    fitFontSize.current = resizedFontSize;

    textFitSizeRef.current.style.fontSize = `${fitFontSize.current}px`;

    if (centerText) {
      textFitSizeRef.current.style.textAlign = 'center';
    } else {
      textFitSizeRef.current.style.textAlign = 'start';
    }

    while (
      textFitSizeRef.current.offsetHeight >
        (textFitSizeRef.current.parentElement?.offsetHeight ?? 9000) &&
      fitFontSize.current > 7
    ) {
      fitFontSize.current -= 0.25;

      textFitSizeRef.current.style.fontSize = `${fitFontSize.current}px`;
    }
  };

  useEffect(() => {
    setShow(false);
  }, [dialogue?.dialogueId]);

  useEffect(() => {
    if (show) {
      return;
    }

    findFitTextSize();

    setFontSize(fitFontSize.current);
    setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <ClassicDialogueBoxContainer
        // sx={shakeSx}
        style={{
          border: `${2 * size}px ridge rgb(136, 136, 136)`,
          borderRadius: `${6 * size}px`,
        }}
      >
        <ClassicDialogueBoxTextContainer
          style={{
            visibility: show ? 'visible' : 'hidden',
            textAlign: !centerText ? 'start' : 'center',
            ...(fontSize && { fontSize: `${fontSize}px` }),
          }}
        >
          <DialogueBoxText />
        </ClassicDialogueBoxTextContainer>
      </ClassicDialogueBoxContainer>

      <ClassicDialogueBoxContainer
        style={{
          visibility: 'hidden',
          border: `${2 * size}px ridge rgb(136, 136, 136)`,
          borderRadius: `${6 * size}px`,
        }}
      >
        <ClassicDialogueBoxTextContainer
          style={{
            visibility: 'hidden',
            textAlign: !centerText ? 'start' : 'center',
            ...(fontSize && { fontSize: `${fontSize}px` }),
          }}
        >
          <Box ref={textFitSizeRef}>{dialogue?.fullPlaintext}</Box>
        </ClassicDialogueBoxTextContainer>
      </ClassicDialogueBoxContainer>

      <ClassicNameplate />

      <ClassicDialogueNextButton />
    </>
  );
};

const ClassicNameplate = () => {
  const {
    playerUi: {
      state: { size },
    },
  } = usePlayer();

  const { nameplate, showNameplate } = usePlayerDialogueBoxContext();

  return (
    <ClassicDialogueBoxNameplate
      style={{
        display: !showNameplate ? 'none' : 'inherit',
        border: `${2 * size}px ridge rgb(136, 136, 136)`,
        borderRadius: `${7 * size}px`,
      }}
    >
      <ClassicDialogueBoxNameplateText
        style={{ padding: `0px ${size * 7}px`, fontSize: `${size * 28}px` }}
      >
        {nameplate}
      </ClassicDialogueBoxNameplateText>
    </ClassicDialogueBoxNameplate>
  );
};

export const ClassicDialogueNextButton = () => {
  const { onClick, showNextButton, size } = useNextButton();

  return (
    <ClassicNextButton
      style={{
        display: showNextButton ? 'flex' : 'none',
        border: `${5 * size}px solid #d88643`,
        borderRadius: `${3 * size}px`,
      }}
      onClick={onClick}
    >
      <KeyboardDoubleArrowRight
        sx={continueAnimationClassic}
        style={{
          width: '75%',
          height: 'auto',
          stroke: 'white',
        }}
      />
    </ClassicNextButton>
  );
};

const ClassicDialogueBoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '25%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  zIndex: ZIndex.DialogueBox,
  boxSizing: 'border-box',
}));

const ClassicDialogueBoxTextContainer = styled(Box)(({ theme }) => ({
  padding: '0px 1% 0px 1%',
  color: '#fff',
  fontFamily: 'verdana, sans-serif',
  width: '100%',
  height: '100%',
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  // userSelect: 'all',
  // pointerEvents: 'all',
  // '& *': { pointerEvents: 'all', userSelect: 'all' },
}));

const ClassicDialogueBoxNameplate = styled(Box)(({ theme }) => ({
  zIndex: ZIndex.DialogueBox,
  position: 'absolute',
  left: '0',
  bottom: '24.5%',
  backgroundColor: 'rgb(5, 5, 100)',
  width: 'auto',
}));

const ClassicDialogueBoxNameplateText = styled(Box)(({ theme }) => ({
  width: '100%',
  color: '#ffffff',
  fontFamily: 'verdana, sans-serif',
  whiteSpace: 'nowrap',
  // pointerEvents: 'all',
  // userSelect: 'all',
}));

const ClassicNextButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '62%',
  left: '90.75%',
  zIndex: ZIndex.NextButton,
  color: 'white',
  background: 'linear-gradient(to bottom, #af5000 0%, #731400 100%)',
  width: '8.33%',
  height: '10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px',
  cursor: 'pointer',
  pointerEvents: 'all',
}));
