import { CopyAll, Email, X } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useLocation } from 'react-router-dom';
import { Popper } from './ui/Popper';

const Footer = () => {
  const footerText = `objection.lol • ${
    import.meta.env.VITE_APP_VERSION as string
  }${import.meta.env.VITE_IS_BETA ? '-beta' : ''}`;

  return (
    <Stack>
      <Box component="footer" bgcolor="primary.main" p={1} mt={4}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Impressum />

          <Stack direction="row" spacing={1}>
            <EmailButton />

            <Tooltip title="X (Twitter)" disableInteractive>
              <IconButton
                sx={{ color: 'primary.contrastText' }}
                onClick={handleOpenTwitter}
              >
                <X />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>

      <Box
        py={0.5}
        textAlign="center"
        bgcolor={(theme) =>
          theme.palette.mode === 'dark' ? '#1E1E1E' : '#F5F5F5'
        }
      >
        <Typography variant="caption">{footerText}</Typography>
      </Box>

      <Spacer />
    </Stack>
  );
};

const EmailButton = () => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('objection@objection.lol');
  };

  return (
    <>
      <Tooltip title="Email" disableInteractive>
        <IconButton sx={{ color: 'primary.contrastText' }} onClick={toggle}>
          <Email />
        </IconButton>
      </Tooltip>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        placement="top"
        paperProps={{ sx: { p: 2, minWidth: 250 } }}
      >
        <Typography variant="h5" mb={2}>
          Contact Email
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link href="mailto:objection@objection.lol" underline="none">
            objection@objection.lol
          </Link>

          <Tooltip title="Copy email" placement="top" disableInteractive>
            <IconButton size="small" onClick={handleCopyEmail}>
              <CopyAll />
            </IconButton>
          </Tooltip>
        </Stack>
      </Popper>
    </>
  );
};

const Impressum = () => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(620));

  const { anchorEl, toggle, close } = useAnchorMenu();

  return !breakpoint ? (
    <FooterText color="primary.contrastText" />
  ) : (
    <>
      <Button
        variant="text"
        size="small"
        onClick={toggle}
        sx={{ color: 'primary.contrastText', lineHeight: 0 }}
      >
        Impressum
      </Button>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        placement="top"
        paperProps={{ sx: { p: 1 } }}
      >
        <FooterText color="inherit" />
      </Popper>
    </>
  );
};

const FooterText = ({ color }: { color: string }) => {
  return (
    <Stack spacing={0.5} color={color}>
      <Typography variant="caption">
        Phoenix Wright: Ace Attorney And All Respective Names are Trademark & ©
        of Capcom.
      </Typography>

      <Typography variant="caption">
        This is a fan project and is not affiliated with Capcom.
      </Typography>
    </Stack>
  );
};

const Spacer = () => {
  const location = useLocation();

  if (!location.pathname.startsWith('/maker')) {
    return null;
  }

  return <Box height={56} />;
};

const handleOpenTwitter = () => {
  window.open('https://x.com/objection_lol', '_blank');
};

export default Footer;
