import { Star } from '@mui/icons-material';
import {
  AutocompleteRenderOptionState,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

export const BaseRenderAutocompleteOptionWithFavorite = ({
  optionId,
  optionState,
  props,
  favoriteList,
  handleFavoriteToggle,
  children,
}: {
  optionId: string | number | null;
  optionState: AutocompleteRenderOptionState;
  props: React.HTMLAttributes<HTMLLIElement>;
  favoriteList: (string | number)[];
  handleFavoriteToggle: (id: string | number | null) => void;
  children: React.ReactNode;
}) => {
  const isFavorite = optionId && favoriteList.includes(optionId);

  return (
    <li {...props}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {children}

        <IconButton
          size="small"
          sx={{ visibility: optionId ? 'visible' : 'hidden' }}
          onClick={(event) => {
            event.stopPropagation();

            handleFavoriteToggle(optionId);
          }}
        >
          <Star color={isFavorite ? 'warning' : 'inherit'} />
        </IconButton>
      </Stack>
    </li>
  );
};

export const RenderAutocompleteOptionWithFavorite = (props: {
  optionId: string | number | null;
  optionName: string;
  optionState: AutocompleteRenderOptionState;
  props: React.HTMLAttributes<HTMLLIElement>;
  favoriteList: (string | number)[];
  handleFavoriteToggle: (id: string | number | null) => void;
}) => {
  return (
    <BaseRenderAutocompleteOptionWithFavorite {...props}>
      <Typography variant="body2" noWrap={true} color="textPrimary">
        {props.optionName}
      </Typography>
    </BaseRenderAutocompleteOptionWithFavorite>
  );
};
