import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ApiClient } from '@web/api/api-client';
import { DeleteConfirmation } from '@web/components/common/ui/DeleteConfirmation';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { enqueueSnackbar } from 'notistack';
import { memo } from 'react';
import { useCharacterEditor } from '../providers/CharacterEditorProvider';
import { updateCharacterPoses, updateCharacterSpeechBubbles } from './utils';

export const PoseDeleteButton = memo(({ id }: { id: number }) => {
  const queryClient = useQueryClient();

  const state = useCharacterEditor();
  const deleteConfirmation = useDeleteConfirmation();

  const handlePoseDelete = async () => {
    try {
      await ApiClient.assets.pose.delete(id);

      state.character.poses = state.character.poses.filter((p) => p.id !== id);

      queryClient.invalidateQueries({
        queryKey: ['thumbnail', 'pose', id, true],
      });

      updateCharacterPoses(state.characterId, state.character.poses);
    } catch (error) {
      enqueueSnackbar('Failed to delete pose', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleDelete = async () => {
    await deleteConfirmation.confirm(handlePoseDelete);
  };

  return (
    <DeleteConfirmation
      deleteConfirmation={deleteConfirmation}
      handleDelete={handleDelete}
      message="Delete this pose?"
    >
      <Button
        variant="outlined"
        color="error"
        onClick={deleteConfirmation.startConfirming}
      >
        Delete
      </Button>
    </DeleteConfirmation>
  );
});

export const SpeechBubbleDeleteButton = memo(({ id }: { id: number }) => {
  const state = useCharacterEditor();
  const deleteConfirmation = useDeleteConfirmation();

  const handleSpeechBubbleDelete = async () => {
    try {
      await ApiClient.assets.characterSpeechBubble.delete(id);

      state.character.speechBubbles = state.character.speechBubbles.filter(
        (p) => p.id !== id,
      );

      updateCharacterSpeechBubbles(
        state.characterId,
        state.character.speechBubbles,
      );
    } catch (error) {
      enqueueSnackbar('Failed to delete speech bubble', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleDelete = async () => {
    await deleteConfirmation.confirm(handleSpeechBubbleDelete);
  };

  return (
    <DeleteConfirmation
      deleteConfirmation={deleteConfirmation}
      handleDelete={handleDelete}
      message="Delete this speech bubble?"
    >
      <Button
        variant="outlined"
        color="error"
        onClick={deleteConfirmation.startConfirming}
      >
        Delete
      </Button>
    </DeleteConfirmation>
  );
});

export const PoseExtraDeleteButton = memo(
  ({
    id,
    label,
    poseIndex,
    propKey,
    apiKey,
  }: {
    id: number;
    label: string;
    poseIndex: number;
    propKey: 'poseStates' | 'poseAudioTicks' | 'poseFunctionTicks';
    apiKey: keyof typeof ApiClient.assets;
  }) => {
    const state = useCharacterEditor();
    const deleteConfirmation = useDeleteConfirmation();

    const handlePoseExtraDelete = async () => {
      try {
        // @ts-expect-error id is number or string
        await ApiClient.assets[apiKey].delete(id);

        state.character.poses[poseIndex][propKey] = state.character.poses[
          poseIndex
        ][
          propKey
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ].filter((p) => p.id !== id) as any[];

        updateCharacterPoses(state.characterId, state.character.poses);
      } catch (error) {
        enqueueSnackbar(`Failed to delete ${label}`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };

    const handleDelete = async () => {
      await deleteConfirmation.confirm(handlePoseExtraDelete);
    };

    return (
      <DeleteConfirmation
        deleteConfirmation={deleteConfirmation}
        handleDelete={handleDelete}
        message={`Delete this ${label}?`}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={deleteConfirmation.startConfirming}
        >
          Delete
        </Button>
      </DeleteConfirmation>
    );
  },
);
