import { Gavel, Videocam } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { ProjectType } from '@shared/types';
import { makerActions } from '@web/store/maker/actions';
import { makerStore } from '@web/store/maker/state';
import React from 'react';
import { useSnapshot } from 'valtio';

export const NewProjectDialog = React.memo(() => {
  const [projectType, setProjectType] = React.useState(ProjectType.Scene);

  const snapshot = useSnapshot(makerStore.dialogs);

  const handleCreate = () => {
    handleClose();

    makerActions.newProject(projectType);

    makerActions.addFrame(0);

    // for (let i = 0; i < 49; i++) {
    //   makerActions.addFrame(0);
    // }

    // makerStore.perPage = 50;
  };

  const handleClose = () => {
    makerStore.dialogs.newProject = false;
  };

  return (
    <Dialog
      open={!!snapshot.newProject}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>New Project</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Creating a new project will delete all existing frames.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Choose the project type:
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="contained"
              color={projectType === 'scene' ? 'primary' : 'inherit'}
              size="large"
              startIcon={<Videocam fontSize="large" />}
              onClick={() => setProjectType(ProjectType.Scene)}
              fullWidth
            >
              Scene
            </Button>

            <Button
              variant="contained"
              color={projectType === 'case' ? 'primary' : 'inherit'}
              size="large"
              startIcon={<Gavel fontSize="large" />}
              onClick={() => setProjectType(ProjectType.Case)}
              disabled
              fullWidth
            >
              Case
            </Button>
          </Stack>

          <List
            sx={{ listStyle: 'disc', paddingLeft: 2, color: 'text.secondary' }}
            dense
          >
            {projectType === 'scene' ? (
              <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
                <ListItemText
                  color="text.secondary"
                  primary="Linear series of frames that can be recorded or played on a browser."
                />
              </ListItem>
            ) : (
              <>
                <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
                  <ListItemText
                    color="text.secondary"
                    primary="Interactive case playable only on a browser."
                  />
                </ListItem>
                <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
                  <ListItemText primary="Case actions available in addition to frame actions." />
                </ListItem>
                <ListItem dense sx={{ display: 'list-item', padding: 0 }}>
                  <ListItemText primary="No seek bar." />
                </ListItem>
              </>
            )}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
});
