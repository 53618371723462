import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Stack, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { UpdatePoseDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { setFormErrors } from '@web/components/common/form/Form';
import { getErrorMessage, ValidationError } from '@web/utils/error';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useProxy } from 'valtio/utils';
import * as yup from 'yup';
import { PoseUpdateWatcher } from '../CharacterUpdateWatchers';
import { useCharacterEditor } from '../providers/CharacterEditorProvider';
import { PoseDeleteButton } from './PoseDeleteButtons';
import { updateCharacterPoses } from './utils';

export const MainPoseEditor = memo(({ index }: { index: number }) => {
  const state = useCharacterEditor();
  const { character } = useProxy(state);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().max(80).required(),
        idleImageUrl: urlValidation().required('Idle Image URL is required'),
        speakImageUrl: urlValidation().defined(),
        iconUrl: urlValidation().defined(),
        isSpeedlines: yup.boolean().defined(),
        order: yup.number().defined(),
      }),
    [],
  );

  const defaultValue: UpdatePoseDto = (({
    name,
    idleImageUrl,
    speakImageUrl,
    iconUrl,
    isSpeedlines,
    order,
  }) => ({
    name,
    idleImageUrl,
    speakImageUrl,
    iconUrl,
    isSpeedlines,
    order,
  }))(character.poses[index]);

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    setError,
    handleSubmit,
  } = useForm<UpdatePoseDto>({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();

  const onSubmit = async (data: UpdatePoseDto) => {
    try {
      const pose = state.character.poses[index];

      const result =
        pose.id < 0
          ? await ApiClient.assets.pose.create({
              ...data,
              characterId: state.characterId,
            })
          : await ApiClient.assets.pose.update(pose.id, data);

      state.character.poses[index] = {
        ...state.character.poses[index],
        ...result.data,
      };

      queryClient.invalidateQueries({
        queryKey: ['thumbnail', 'pose', pose.id, true],
      });

      updateCharacterPoses(state.characterId, state.character.poses);

      enqueueSnackbar('Pose saved', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        setFormErrors(err, setError);
      } else {
        setError('root', { message: getErrorMessage(err) });
      }
    }
  };

  const handleCancel = (index: number) => {
    state.character.poses = state.character.poses.filter((_, i) => i !== index);
  };

  return (
    <Box component="form">
      <Stack spacing={2}>
        {errors.root?.message && (
          <Alert severity="error" variant="filled">
            {errors.root.message}
          </Alert>
        )}

        <TextField
          label="Name"
          {...register('name')}
          variant="standard"
          size="small"
          error={!!errors.name}
          helperText={errors.name?.message}
          fullWidth
        />

        <TextField
          label="Idle Image URL"
          {...register('idleImageUrl')}
          variant="standard"
          size="small"
          error={!!errors.idleImageUrl}
          helperText={errors.idleImageUrl?.message}
          fullWidth
        />

        <TextField
          label="Talking Image URL"
          {...register('speakImageUrl')}
          variant="standard"
          size="small"
          error={!!errors.speakImageUrl}
          helperText={errors.speakImageUrl?.message}
          fullWidth
        />

        <TextField
          label="Icon Image URL"
          {...register('iconUrl')}
          variant="standard"
          size="small"
          error={!!errors.iconUrl}
          helperText={errors.iconUrl?.message}
          fullWidth
        />

        <Controller
          control={control}
          name="isSpeedlines"
          render={({ field }) => (
            <Checkbox
              label="Override background with speedlines"
              value={!!field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          py={2}
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Stack>

          {character.poses[index].id > 0 && (
            <PoseDeleteButton id={character.poses[index].id} />
          )}

          {character.poses[index].id < 0 && (
            <Button variant="outlined" onClick={() => handleCancel(index)}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>

      <PoseUpdateWatcher control={control} index={index} />
    </Box>
  );
});
