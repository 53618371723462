import {
  Chip,
  Paper,
  PaperProps,
  SelectVariants,
  Stack,
  Typography,
} from '@mui/material';
import { DialogueBoxDto } from '@web/api/api';
import { BaseRenderAutocompleteOptionWithFavorite } from '@web/components/common/ui/RenderAutocompleteOptionWithFavorite';
import { useToggleValue } from '@web/hooks/useToggleValue';
import { useAssetStore } from '@web/store/assets/state';
import { forwardRef, useMemo } from 'react';
import {
  AutocompleteWithFavorites,
  StyledAutocompletePopper,
} from '../../common/form/AutocompleteWithFavorites';
import { RenderAutocompleteInput } from '../../common/ui/RenderAutocompleteInput';
import { VirtualizedListbox } from '../../common/ui/VirtualizedListbox';
import { useAssetOptions } from '../hooks/useAssetOptions';

type DialogueBoxPickerProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  title?: string;
  variant?: SelectVariants;
};

export const DialogueBoxPicker = ({
  value,
  onChange,
  variant,
  title,
}: DialogueBoxPickerProps) => {
  const {
    dialogueBox: { preset, user },
  } = useAssetStore();

  const [filter, setFilter] = useToggleValue<'preset' | 'custom' | undefined>(
    undefined,
  );

  const presetList = useMemo(
    () => [{ id: '0', name: 'Classic' }, ...preset],
    [preset],
  );

  const { selectedValue, sortedOptions } = useAssetOptions({
    filter,
    value,
    type: 'dialogueBox',
    presetList: presetList,
    userList: user,
  });

  return (
    <AutocompleteWithFavorites
      type="dialogueBox"
      label=""
      size="small"
      ListboxComponent={DialogueBoxVirtualizedListBox}
      // ListboxProps={{ style: { maxHeight: '364px' } }}
      PopperComponent={StyledAutocompletePopper}
      PaperComponent={DialogueBoxTagPaper}
      value={selectedValue}
      onChange={(_, value) => onChange(value?.id ?? null)}
      options={sortedOptions}
      slotProps={{
        paper: {
          filter,
          setFilter,
        } as PaperProps,
      }}
      renderInput={(params) => (
        <RenderAutocompleteInput
          variant={variant || 'standard'}
          label={title}
          params={params}
        />
      )}
      renderOption={(
        props,
        option,
        optionState,
        favoriteList,
        handleFavoriteToggle,
      ) => {
        const dialogueBox = option as DialogueBoxDto;

        return (
          <BaseRenderAutocompleteOptionWithFavorite
            key={option.id}
            props={props}
            optionId={option.id}
            optionState={optionState}
            favoriteList={favoriteList}
            handleFavoriteToggle={handleFavoriteToggle}
          >
            <RenderDialogueBoxOption dialogueBox={dialogueBox} />
          </BaseRenderAutocompleteOptionWithFavorite>
        );
      }}
      disableClearable
    />
  );
};

const RenderDialogueBoxOption = ({
  dialogueBox,
}: {
  dialogueBox: DialogueBoxDto;
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" overflow="hidden">
      {/* <AssetImageServerIcon
        id={dialogueBox.id}
        type="dialogueBox"
        alt={dialogueBox.name}
      /> */}

      <Typography variant="body2" noWrap={true} color="textPrimary">
        {dialogueBox.name}
      </Typography>
    </Stack>
  );
};

type AudioTagPaperProps = {
  filter: 'preset' | 'custom' | undefined;
  setFilter: (value: 'preset' | 'custom' | undefined) => void;
} & PaperProps;

const DialogueBoxTagPaper = (props: AudioTagPaperProps) => {
  const { children, filter, setFilter, ...other } = props;

  return (
    <Paper {...other} onMouseDown={(event) => event.preventDefault()}>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Chip
          label="Preset"
          color={filter === 'preset' ? 'info' : 'default'}
          onClick={() => setFilter('preset')}
          size="small"
          clickable
        />
        <Chip
          label="Custom"
          color={filter === 'custom' ? 'info' : 'default'}
          onClick={() => setFilter('custom')}
          size="small"
          clickable
        />
      </Stack>
      {children}
    </Paper>
  );
};

const DialogueBoxVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={46} height={46 * 7}>
      {props.children}
    </VirtualizedListbox>
  );
});
