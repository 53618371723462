import {
  BugReport,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SyncAlt,
} from '@mui/icons-material';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  DialogContent,
  FormControlLabel,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CaseFrame, Pair } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { ApiClient } from '@web/api/api-client';
import { assetDialogState } from '@web/components/assets/dialogs/AssetsDialogState';
import { getDefaultAssetUpdateValue } from '@web/components/assets/utils';
import {
  NumberTextField,
  NumberTextFieldWithControls,
} from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import { DialogTransitionGrow } from '@web/components/common/ui/Dialog';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { Popper } from '@web/components/common/ui/Popper';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { BackgroundPicker } from '@web/components/maker/ui/BackgroundPicker';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import easings from '@web/data/easings';
import { presetSpeechBlips as dataPresetSpeechBlips } from '@web/data/preset/preset-speech-blips';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { assetStore, useAssetStore } from '@web/store/assets/state';
import { makerStore, updateFrame, useMakerStore } from '@web/store/maker/state';
import { rootStore } from '@web/store/root/state';
import { WithIdAndName } from '@web/types';
import { getErrorMessage } from '@web/utils/error';
import { isNumber } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import {
  FrameAndIndexContext,
  FrameAndIndexProvider,
  useFrameAndIndexContext,
} from '../providers/FrameContextProvider';
import { AdornmentButton } from '../ui/AdornmentButton';
import { CharacterPicker } from '../ui/CharacterPicker';
import { DualGridPicker } from '../ui/DualGridPicker';
import { PopupPicker } from '../ui/PopupPicker';
import { PosePicker } from '../ui/PosePicker';
import { SpeechBubblePicker } from '../ui/SpeechBubblePicker';
import { PairingUtils } from '../utils/pairing';

export const SceneEditorDialog = () => {
  const {
    dialogs: { sceneEditor },
  } = useMakerStore();

  const handleClose = useCallback(() => {
    makerStore.dialogs.sceneEditor = false;
  }, []);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={!!sceneEditor}
      onClose={handleClose}
      titleComponent={<SceneEditorDialogTitle />}
      TransitionComponent={DialogTransitionGrow}
      transitionDuration={100}
      disableEnforceFocus
      fullscreen={sm}
      PaperProps={{ elevation: 1, sx: { maxWidth: '700px' } }}
      fullWidth
      keepMounted
    >
      <DialogContent sx={{ p: { xs: 1, sm: 2 } }}>
        <SceneEditorDialogContent />
      </DialogContent>
    </DraggableDialog>
  );
};

const SceneEditorDialogContent = memo(() => {
  const { frames, project, sceneEditorFrameIndex } = useMakerStore();

  const pair = useMemo<Pair | undefined>(() => {
    if (
      !isNumber(sceneEditorFrameIndex) ||
      !frames[sceneEditorFrameIndex]?.pair
    )
      return undefined;

    return project?.pairs?.find(
      (pair) => pair.id === frames[sceneEditorFrameIndex].pair?.id,
    );
  }, [frames, project?.pairs, sceneEditorFrameIndex]);

  if (
    (!sceneEditorFrameIndex && sceneEditorFrameIndex !== 0) ||
    !frames.length
  ) {
    return null;
  }

  const contextValue: FrameAndIndexContext = {
    frame: frames[sceneEditorFrameIndex] || ({} as CaseFrame),
    index: sceneEditorFrameIndex ?? 0,
    pair,
  };

  return (
    <FrameAndIndexProvider value={contextValue}>
      <Stack spacing={1}>
        <DialogBackgroundPicker />
        <DialogWideBackgroundOptions />

        <DialogThumbnail />

        <ManageCustomCharacter />

        <DualGridPicker>
          <DialogCharacterPicker />
          <DialogPosePicker />
        </DualGridPicker>

        <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
          <DialogSpeechBubblePicker />

          <DialogCustomNameInput />
        </Stack>

        <DualGridPicker>
          <DialogPopupPicker />
          <DialogPairPicker />
        </DualGridPicker>

        <DualGridPicker>
          <DialogFlipPicker />
          <DialogSpeechblipPicker />
          <DialogSpeechblipPicker />
        </DualGridPicker>

        <Box pt={1}>
          <DialogCheckboxesFrameProperties />
        </Box>
      </Stack>
    </FrameAndIndexProvider>
  );
});

const checkIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const DialogCheckboxesFrameProperties = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleToggleFramePoseAnimation = useCallback(() => {
    updateFrame({ noPoseAnimation: !frame.noPoseAnimation }, index);
  }, [frame.noPoseAnimation, index]);

  const handleToggleDoNotTalk = useCallback(() => {
    updateFrame({ doNotTalk: !frame.doNotTalk }, index);
  }, [frame.doNotTalk, index]);

  const handleToggleNoPauseForAnimation = useCallback(() => {
    updateFrame({ noPauseForAnimation: !frame.noPauseForAnimation }, index);
  }, [frame.noPauseForAnimation, index]);

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <FormControlLabel
        label="Pose Animation"
        sx={{ mr: 0 }}
        control={
          <Checkbox
            checked={!frame.noPoseAnimation}
            onChange={handleToggleFramePoseAnimation}
            size="small"
          />
        }
      />

      <FormControlLabel
        label="No Talking Animation"
        sx={{ mr: 0 }}
        control={
          <Checkbox
            checked={!!frame.doNotTalk}
            onChange={handleToggleDoNotTalk}
            size="small"
          />
        }
      />

      <FormControlLabel
        label="Skip Animation Delay"
        sx={{ mr: 0 }}
        control={
          <Checkbox
            checked={!!frame.noPauseForAnimation}
            onChange={handleToggleNoPauseForAnimation}
            size="small"
          />
        }
      />
    </Stack>
  );
});

const DialogThumbnail = memo(() => {
  const { frame, pair } = useFrameAndIndexContext();

  return (
    <Thumbnail
      key={frame.backgroundId}
      frame={frame}
      pair={pair}
      serverThumbnails={false}
    />
  );
});

const DialogFlipPicker = memo(() => {
  const { frame, index, pair } = useFrameAndIndexContext();

  const options = useMemo(
    () => [
      { label: 'Background', shortLabel: 'BG', value: SceneTargets.BACKGROUND },
      {
        label: pair ? 'Paired Character 1' : 'Character',
        shortLabel: pair ? 'PC 1' : 'C',
        value: SceneTargets.CHARACTER_1,
      },
      ...(pair
        ? [
            {
              label: 'Paired Character 2',
              shortLabel: 'PC 2',
              value: SceneTargets.CHARACTER_2,
            },
            {
              label: 'Paired Character 3',
              shortLabel: 'PC 3',
              value: SceneTargets.CHARACTER_3,
            },
            {
              label: 'Paired Character 4',
              shortLabel: 'PC 4',
              value: SceneTargets.CHARACTER_4,
            },
            {
              label: 'Paired Character 5',
              shortLabel: 'PC 5',
              value: SceneTargets.CHARACTER_5,
            },
          ]
        : []),
    ],
    [pair],
  );

  const selectedOptions = useMemo(() => {
    if (!frame.flipped) {
      return [];
    }

    return options.filter(
      (option) => (frame.flipped! & option.value) === option.value,
    );
  }, [frame.flipped, options]);

  return (
    <Autocomplete
      options={options}
      value={selectedOptions}
      onChange={(event, newValue) => {
        const newFlippedValue = newValue.reduce(
          (acc, curr) => acc | curr.value,
          0,
        );

        updateFrame({ flipped: newFlippedValue }, index);
      }}
      size="small"
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label="Flip"
          variant="standard"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={checkIcon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.value}
            label={option.shortLabel}
            size="small"
          />
        ))
      }
      multiple={true}
      disableCloseOnSelect
      disableClearable
      fullWidth
    />
  );
});

const DialogPairPicker = memo(() => {
  const { project } = useMakerStore();
  const { frame, index } = useFrameAndIndexContext();

  const options = useMemo(
    () =>
      (project?.pairs ?? []).map((m) => ({
        id: m.id,
        name: m.name,
        pairs: m.pairs,
      })),
    [project?.pairs],
  );

  const selectedValue = useMemo(
    () => options.find((option) => option.id === frame.pair?.id) ?? null,
    [frame.pair?.id, options],
  );

  const handleChangePair = useCallback(
    (value: typeof selectedValue) => {
      if (value === null) {
        updateFrame({ pair: undefined }, index);
      } else {
        const mainCharacterPoseId = value.pairs[0].characterId
          ? assetStore.character.cache[value.pairs[0].characterId].poses[0]?.id
          : undefined;

        const poseIds = value.pairs
          .slice(1)
          .reduce<Record<number, number | null>>((acc, curr) => {
            acc[curr.characterId ?? ''] = curr.characterId
              ? (assetStore.character.cache[curr.characterId].poses[0]?.id ??
                null)
              : null;

            return acc;
          }, {});

        updateFrame(
          {
            characterId: value.pairs[0].characterId ?? undefined,
            poseId: mainCharacterPoseId,
            pair: { id: value.id, poseIds: poseIds },
          },
          index,
        );
      }
    },
    [index],
  );

  return (
    <SimpleSelect
      label="Pair Group"
      options={options}
      value={selectedValue}
      onChange={handleChangePair}
      size="small"
      variant="standard"
      clearable
      fullWidth
    />
  );
});

const DialogSpeechblipPicker = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const {
    character: { user: customCharacters },
  } = useSnapshot(assetStore);

  const presetSpeechBlips = useMemo(
    () => dataPresetSpeechBlips.map((m) => ({ id: m.id, label: m.label })),
    [],
  );

  const customSpeechBlips = useMemo(
    () =>
      customCharacters
        .map((m) => ({
          id: m.id,
          label: m.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [customCharacters],
  );

  const blipOptions = useMemo(() => {
    return [...presetSpeechBlips, ...customSpeechBlips];
  }, [presetSpeechBlips, customSpeechBlips]);

  const selectedOption = useMemo(() => {
    return blipOptions.find((option) => option.id === frame.blip) ?? null;
  }, [frame.blip, blipOptions]);

  const handleChangeBlip = useCallback(
    (value: number | null) => {
      if (value === null) {
        updateFrame({ blip: undefined }, index);
      } else {
        updateFrame({ blip: value }, index);
      }
    },
    [index],
  );

  return (
    <Autocomplete
      value={selectedOption}
      options={blipOptions}
      onChange={(_, v) => handleChangeBlip(v?.id ?? null)}
      size="small"
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label="Speech Blip"
          variant="standard"
          InputProps={{
            endAdornment: selectedOption ? (
              <AdornmentButton onClick={() => handleChangeBlip(null)} />
            ) : null,
          }}
        />
      )}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.id}
      disableClearable={selectedOption !== null}
      groupBy={(option) => (option.id < 7 ? 'Preset' : 'Custom')}
      ListboxProps={{ sx: { pt: 0 } }}
      blurOnSelect
      fullWidth
    />
  );
});

const DialogPopupPicker = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangePopup = useCallback(
    (value: number | null) => {
      if (value === null) {
        updateFrame({ popupId: undefined }, index);
      } else {
        updateFrame({ popupId: value }, index);
      }
    },
    [index],
  );

  return (
    <PopupPicker value={frame.popupId ?? null} onChange={handleChangePopup} />
  );
});

const DialogCustomNameInput = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangeCustomName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateFrame({ customName: e.target.value }, index);
    },
    [index],
  );

  const handleClearCustomName = useCallback(() => {
    updateFrame({ customName: undefined }, index);
  }, [index]);

  return (
    <TextField
      label="Custom Name"
      variant="standard"
      value={frame.customName ?? ''}
      onChange={handleChangeCustomName}
      inputProps={{ maxLength: 100, autoComplete: 'off' }}
      InputProps={{
        endAdornment: frame.customName?.length ? (
          <AdornmentButton onClick={handleClearCustomName} />
        ) : null,
      }}
      fullWidth
    />
  );
});

const DialogCharacterPicker = memo(() => {
  const { frame, index, pair } = useFrameAndIndexContext();

  const handleChangeCharacter = useCallback(
    (value: number | null) => {
      if (value === null) {
        updateFrame({ characterId: undefined, poseId: undefined }, index);
      } else {
        updateFrame({ characterId: value }, index);

        const character = assetStore.character.cache[value];

        if (character) {
          updateFrame({ poseId: character.poses[0]?.id }, index);
        }
      }
    },
    [index],
  );

  return !pair ? (
    <CharacterPicker
      value={frame.characterId ?? 0}
      onChange={handleChangeCharacter}
    />
  ) : (
    <DialogMainPairCharacterPicker />
  );
});

const DialogMainPairCharacterPicker = memo(() => {
  const { frame, index, pair } = useFrameAndIndexContext();
  const {
    character: { cache },
  } = useAssetStore();

  const pairedCharactersOptions = useMemo(() => {
    if (!pair) {
      return [];
    }

    const uniqueCharacters = new Map<number, WithIdAndName>();

    pair.pairs
      .filter((f) => !!f.characterId)
      .forEach((m) => {
        if (!uniqueCharacters.has(m.characterId!)) {
          uniqueCharacters.set(m.characterId!, {
            id: m.characterId,
            name: cache[m.characterId!]?.name,
          } as WithIdAndName);
        }
      });

    return Array.from(uniqueCharacters.values());
  }, [cache, pair]);

  const selectedMainCharacter = useMemo(() => {
    if (!pair) {
      return null;
    }

    return (
      pairedCharactersOptions?.find((f) => f.id === frame.characterId) ?? null
    );
  }, [frame.characterId, pair, pairedCharactersOptions]);

  const handleChangeMainCharacter = useCallback(
    (value: typeof selectedMainCharacter) => {
      if (!value || !value.id) {
        updateFrame({ characterId: undefined, poseId: undefined }, index);

        return;
      }

      const character = assetStore.character.cache[value.id];

      if (character) {
        updateFrame(
          PairingUtils.changeMainCharacter(frame, Number(value.id)),
          index,
        );
      }
    },
    [frame, index],
  );

  return (
    <SimpleSelect
      options={pairedCharactersOptions}
      value={selectedMainCharacter}
      onChange={handleChangeMainCharacter}
      size="small"
    />
  );
});

const DialogBackgroundPicker = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangeBackground = useCallback(
    (value: number | null) => {
      if (value === null) {
        updateFrame({ backgroundId: undefined }, index);
      } else {
        updateFrame({ backgroundId: value }, index);
      }
    },
    [index],
  );

  return (
    <BackgroundPicker
      value={frame.backgroundId ?? null}
      onChange={handleChangeBackground}
    />
  );
});

const DialogWideBackgroundOptions = memo(() => {
  const { frame, index } = useFrameAndIndexContext();
  const {
    background: { cache },
  } = useAssetStore();

  const handleChangeLeft = useCallback(
    (value: number) => {
      updateFrame(
        {
          transition: frame.transition
            ? { ...frame.transition, left: value }
            : { duration: 0, left: value, easing: 'linear' },
        },
        index,
      );
    },
    [frame.transition, index],
  );

  if (!frame || !frame.backgroundId || !cache[frame.backgroundId]?.isWide) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      <ButtonGroup
        variant="outlined"
        sx={{ display: 'flex', justifyContent: 'center' }}
        color="inherit"
      >
        <XSmallButton onClick={() => handleChangeLeft(0)} size="small">
          <FormatAlignLeft />
        </XSmallButton>
        <XSmallButton onClick={() => handleChangeLeft(50)} size="small">
          <FormatAlignCenter />
        </XSmallButton>
        <XSmallButton onClick={() => handleChangeLeft(100)} size="small">
          <FormatAlignRight />
        </XSmallButton>
      </ButtonGroup>

      <NumberTextFieldWithControls
        value={frame.transition?.left}
        onChange={(e) => handleChangeLeft(Number(e.target.value))}
        min={0}
        max={100}
        step={0.5}
        size="small"
        sx={{ width: 150 }}
      />

      <Box flexGrow={1} />

      <TransitionButton />
    </Stack>
  );
});

const TransitionButton = memo(() => {
  const {
    dialogs: { sceneEditor },
  } = useMakerStore();
  const { frame, index } = useFrameAndIndexContext();
  const { frames } = useMakerStore();
  const {
    background: { cache },
  } = useAssetStore();

  const { anchorEl, close, toggle } = useAnchorMenu();

  const previousFrame = frames?.[index - 1] || undefined;
  const transition = frame?.transition;

  const canTransition =
    frame &&
    previousFrame &&
    frame.backgroundId &&
    frame.backgroundId === previousFrame.backgroundId &&
    cache[frame.backgroundId]?.isWide;

  const handleChangeDuration: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const value = Number(e.target.value);

    updateFrame(
      {
        ...frame,
        transition: transition
          ? { ...transition, duration: value }
          : { duration: value, left: 0, easing: 'linear' },
      },
      index,
    );
  };

  const easingOptions = useMemo(
    () =>
      easings.map((m) => ({
        id: m.id,
        name: m.text,
      })),
    [],
  );

  const selectedEasing = useMemo(
    () =>
      easingOptions.find((m) => m.id === transition?.easing) ||
      easingOptions[0],
    [easingOptions, transition?.easing],
  );

  const handleChangeEasing = useCallback(
    (value: typeof selectedEasing | null) => {
      updateFrame(
        {
          ...frame,
          transition: transition
            ? { ...transition, easing: value?.id || null }
            : { duration: 0, left: 0, easing: value?.id || null },
        },
        index,
      );
    },
    [frame, index, transition],
  );

  const handleClear = useCallback(() => {
    updateFrame(
      {
        ...frame,
        transition:
          transition && transition.left
            ? { ...transition, easing: null, duration: 0 }
            : undefined,
      },
      index,
    );
  }, [frame, index, transition]);

  useEffect(() => {
    if (!sceneEditor && !!anchorEl) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneEditor]);

  return (
    <>
      <Tooltip
        title={
          !canTransition
            ? 'The background in the previous frame must match the background in this frame'
            : null
        }
      >
        <Box alignSelf="end">
          <XSmallButton
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SyncAlt />}
            disabled={!canTransition}
            onClick={toggle}
          >
            Transition
          </XSmallButton>
        </Box>
      </Tooltip>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ width: 320 }}
        placement="auto-end"
        transitionDuration={0}
        persist
        disablePortal
      >
        <AppBar
          position="static"
          color="accent"
          elevation={0}
          enableColorOnDark
        >
          <Toolbar color="primary" variant="dense" disableGutters>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              px={2}
              spacing={1}
              flexGrow={1}
            >
              <Typography variant="h6">Transition</Typography>
              <Box flexGrow={1} />
              <Button variant="text" color="inherit" onClick={close}>
                Close
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>

        <Stack spacing={1} p={2}>
          <NumberTextField
            label="Duration (ms)"
            value={transition?.duration || 0}
            onChange={handleChangeDuration}
            size="small"
            disabled={!canTransition}
          />

          <SimpleSelect
            label="Easing"
            options={easingOptions}
            value={selectedEasing}
            onChange={handleChangeEasing}
            size="small"
            disabled={!canTransition}
          />

          <Box pt={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleClear}
              startIcon={<Delete />}
            >
              Clear
            </Button>
          </Box>
        </Stack>
      </Popper>
    </>
  );
});

const ManageCustomCharacter = memo(() => {
  const [loading, setLoading] = useState(false);

  const { frame } = useFrameAndIndexContext();
  const {
    character: { cache, user },
  } = useAssetStore();

  const isCustom =
    !!frame.characterId &&
    !cache[frame.characterId]?.isPreset &&
    user.find((m) => m.id === frame.characterId);

  const handleClick = async () => {
    try {
      setLoading(true);

      const res = await ApiClient.assets.character.get(frame.characterId!);

      makerStore.dialogs.sceneEditor = false;

      rootStore.assetsDialogTab = 'character';
      assetDialogState.editingAssetId = res.data.id;
      assetDialogState.editingAsset = getDefaultAssetUpdateValue(
        'character',
        res.data,
      );

      rootStore.dialogs.assets = true;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (!isCustom) {
    return null;
  }

  return (
    <Box>
      <Button
        variant="text"
        color="info"
        size="small"
        onClick={handleClick}
        disabled={loading}
      >
        Manage Custom Character
      </Button>
    </Box>
  );
});

const DialogPosePicker = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangePose = useCallback(
    (value: number) => {
      if (value === 0) {
        return;
      }

      updateFrame({ poseId: value }, index);
    },
    [index],
  );

  return (
    <PosePicker
      characterId={frame.characterId}
      value={frame.poseId ?? 0}
      onChange={handleChangePose}
    />
  );
});

const DialogSpeechBubblePicker = memo(() => {
  const { frame, index } = useFrameAndIndexContext();

  const handleChangeSpeechBubble = useCallback(
    (value: number | undefined) => {
      if (!value) {
        updateFrame({ speechBubble: undefined }, index);

        return;
      }

      updateFrame({ speechBubble: value }, index);
    },
    [index],
  );

  return (
    <SpeechBubblePicker
      characterId={frame.characterId}
      value={frame.speechBubble ?? 0}
      onChange={handleChangeSpeechBubble}
    />
  );
});

const SceneEditorDialogTitle = memo(() => {
  const { sceneEditorFrameIndex, isCaseProject } = useMakerStore();
  const index = sceneEditorFrameIndex ?? 0;

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    makerStore.dialogs.sceneEditor = false;
  };

  const handleNavClick = (direction: number) => {
    makerStore.sceneEditorFrameIndex = Math.max(
      0,
      Math.min(index + direction, makerStore.frames.length - 1),
    );
  };

  const handleDebugFrame = () => {
    console.log('Frame:', makerStore.frames[index]);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      minWidth={0}
    >
      <Typography
        variant="h6"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        noWrap
      >
        Scene Editor [{isCaseProject ? makerStore.frames[index]?.id : index + 1}
        ]
      </Typography>

      <Stack direction="row">
        {import.meta.env.DEV && (
          <AppBarButton onClick={() => handleDebugFrame()}>
            <BugReport />
          </AppBarButton>
        )}
        <AppBarButton onClick={() => handleNavClick(-1)}>
          <KeyboardArrowLeft />
        </AppBarButton>
        <AppBarButton onClick={() => handleNavClick(1)}>
          <KeyboardArrowRight />
        </AppBarButton>
        <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
      </Stack>
    </Stack>
  );
});
