import { handleTokenRefreshFailure } from '@web/providers/auth/authService';
import { rootStore } from '@web/store/root/state';
import { ValidationError } from '@web/utils/error';
import axios from 'axios';

const basePath = (import.meta.env.VITE_BACKEND_BASE_URL ?? '/api') as string;
const axiosInstance = axios.create({ withCredentials: true });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config, response } = error;
    const originalRequest = config;
    const status = response?.status;

    if (
      status === 401 &&
      !originalRequest._retry &&
      localStorage.getItem('token') &&
      !originalRequest.url.endsWith('/refresh')
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        fetch(`${basePath}/auth/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: localStorage.getItem('token') }),
        })
          .then((res) => res.json())
          .then((res) => {
            localStorage.setItem('token', res.token);
            onRefreshed(res.token);
          })
          .catch((err) => {
            handleTokenRefreshFailure();
          })
          .finally(() => {
            isRefreshing = false;
            refreshSubscribers = [];
          });
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token: string) => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          originalRequest._retry = true;

          resolve(axiosInstance(originalRequest));
        });
      });

      return retryOrigReq;
    } else if (
      status === 503 &&
      !originalRequest.url.endsWith('auth/me') &&
      !originalRequest.url.endsWith('/export')
    ) {
      rootStore.dialogs.maintenance = true;

      return Promise.reject(error);
    } else if (
      status === 400 &&
      response.data &&
      typeof response.data === 'object'
    ) {
      return Promise.reject(new ValidationError(error));
    } else {
      return Promise.reject(error);
    }
  },
);

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token));
};

export default axiosInstance;
