import {
  Box,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { OptionsDtoAspectRatioEnum } from '@web/api/api';
import { ComboBox } from '@web/components/common/form/ComboBox';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { AppBarButton } from '@web/components/common/ui/AppBarButton';
import { DraggableDialog } from '@web/components/common/ui/DraggableDialog';
import { getHeightFromAspectRatioLabel } from '@web/components/player/utils/utils';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import React, { ChangeEventHandler, memo, useCallback, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { AdornmentButton } from '../ui/AdornmentButton';
import { DialogueBoxPicker } from '../ui/DialogueBoxPicker';
import { DualGridPicker } from '../ui/DualGridPicker';

export const ProjectOptionsDialog = React.memo(() => {
  const {
    project,
    dialogs: { projectOptions },
  } = useSnapshot(makerStore);

  const handleClose = useCallback(() => {
    makerStore.dialogs.projectOptions = false;
  }, []);

  const handleChangeTextSpeed = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((event) => {
    if (!makerStore.project) return;

    makerStore.project.options.textSpeed = Number(event.target.value);
  }, []);

  const handleChangeBlipFrequency = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((event) => {
    if (!makerStore.project) return;

    makerStore.project.options.textBlipFrequency = Number(event.target.value);
  }, []);

  const handleChangeAutoplaySpeed = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((event) => {
    if (!makerStore.project) return;

    makerStore.project.options.autoplaySpeed = Number(event.target.value);
  }, []);

  const handleChangeWidth = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((event) => {
    if (!makerStore.project) return;

    const newValue = Number(event.target.value);

    makerStore.project.options.width = Math.min(newValue, 1920);
  }, []);

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const height =
    project?.options.width && project?.options.aspectRatio
      ? getHeightFromAspectRatioLabel(
          project.options.width,
          project.options.aspectRatio,
        )
      : '';

  if (!project) return null;

  return (
    <DraggableDialog
      titleComponent={<ProjectOptionsDialogTitle />}
      open={!!projectOptions}
      onClose={handleClose}
      maxWidth="xs"
      fullscreen={fullscreen}
      fullWidth
    >
      <DialogContent sx={{ p: { xs: 1.5, sm: 3 } }}>
        <Stack spacing={2}>
          <DialogueBoxSelect />

          <NumberTextField
            label="Default text speed"
            value={project.options.textSpeed}
            onChange={handleChangeTextSpeed}
            variant="standard"
            helperText="Lower is faster (milliseconds)"
          />

          <NumberTextField
            label="Blip frequency value"
            value={project.options.textBlipFrequency}
            onChange={handleChangeBlipFrequency}
            variant="standard"
            helperText="Controls how often the speech blip sound plays"
          />

          <NumberTextField
            label="Autoplay speed"
            value={project.options.autoplaySpeed}
            onChange={handleChangeAutoplaySpeed}
            variant="standard"
            helperText="The delay in milliseconds between frames when autoplaying or recording. Default: 500"
          />

          <ContinueSoundUrlSelect />

          <AspectRatioSelect />

          <DualGridPicker>
            <NumberTextField
              label="Width"
              value={project.options.width}
              onChange={handleChangeWidth}
              variant="standard"
              fullWidth
            />

            <NumberTextField
              label="Height"
              value={height}
              variant="standard"
              fullWidth
              disabled
            />
          </DualGridPicker>
        </Stack>
      </DialogContent>
    </DraggableDialog>
  );
});

const AspectRatioSelect = memo(() => {
  const { project } = useMakerStore();

  const aspectRatioOptions = useMemo(
    () => [
      { id: OptionsDtoAspectRatioEnum._32, name: '3:2' },
      { id: OptionsDtoAspectRatioEnum._43, name: '4:3' },
      { id: OptionsDtoAspectRatioEnum._169, name: '16:9' },
      { id: OptionsDtoAspectRatioEnum._1610, name: '16:10' },
    ],
    [],
  );

  const selectedAspectRatio = useMemo(
    () =>
      aspectRatioOptions.find((m) => m.id === project?.options?.aspectRatio),
    [aspectRatioOptions, project?.options?.aspectRatio],
  );

  const handleChangeAspectRatio = useCallback(
    (value: { id: OptionsDtoAspectRatioEnum; name: string } | null) => {
      if (value === null || !makerStore.project) return;

      makerStore.project.options.aspectRatio = value.id;
    },
    [],
  );

  return (
    <Box>
      <SimpleSelect
        label="Aspect ratio"
        options={aspectRatioOptions}
        value={selectedAspectRatio ?? null}
        onChange={handleChangeAspectRatio}
        variant="standard"
      />

      <Typography
        display={
          selectedAspectRatio && selectedAspectRatio.id === '3:2'
            ? 'none'
            : 'block'
        }
        variant="caption"
        color="text.secondary"
        mt="3px"
      >
        Not using 3:2 aspect ratio may cause issues with the layout.
      </Typography>
    </Box>
  );
});

const ContinueSoundUrlSelect = memo(() => {
  const { project } = useMakerStore();

  const continueSoundUrlOptions = useMemo(
    () => [{ id: '/Audio/Case/Continue_Trilogy.wav', name: 'Trilogy' }],
    [],
  );

  const handleChangeContinueSound = useCallback((value: string | null) => {
    if (!makerStore.project) return;

    makerStore.project.options.continueSoundUrl = value || '';
  }, []);

  if (!project) return null;

  return (
    <ComboBox
      label="Continue sound"
      value={project.options.continueSoundUrl}
      onChange={handleChangeContinueSound}
      options={continueSoundUrlOptions}
      variant="standard"
      textFieldProps={{
        helperText: 'The sound that plays between frames. Volume: 50%',
        InputProps: {
          endAdornment: (
            <AdornmentButton
              onClick={() => {
                if (!makerStore.project) return;

                handleChangeContinueSound('');
              }}
            />
          ),
        },
      }}
    />
  );
});

const DialogueBoxSelect = memo(() => {
  const { project } = useSnapshot(makerStore);

  const handleChangeDialogueStyle = useCallback((value: string | null) => {
    if (value === null || !makerStore.project) return;

    makerStore.project.options.chatbox = value;
  }, []);

  return (
    <DialogueBoxPicker
      value={project?.options?.chatbox ?? null}
      onChange={handleChangeDialogueStyle}
      title="Dialogue Box"
    />
  );
});

const ProjectOptionsDialogTitle = memo(() => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    makerStore.dialogs.projectOptions = false;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
    >
      <Typography variant="h6">Project Options</Typography>

      <Stack direction="row">
        <AppBarButton onClick={(e) => handleClose(e)}>Close</AppBarButton>
      </Stack>
    </Stack>
  );
});
