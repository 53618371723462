import {
  AccountCircle,
  AdminPanelSettings,
  DarkMode,
  Home,
  InsertDriveFile,
  LightMode,
  Logout,
  Menu as MenuIcon,
  MovieFilter,
  Person,
  Save,
  VideoCall,
  Videocam,
} from '@mui/icons-material';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { assetActions } from '@web/store/assets/actions';
import { rootActions } from '@web/store/root/actions';
import { rootStore, useRootStore } from '@web/store/root/state';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import { setMyObjectionsDialogType } from './dialogs/MyObjectionsDialog';
import { AppBarButton } from './ui/AppBarButton';
import { MenuList } from './ui/MenuList';

const state = proxy({
  openMobileDrawer: false,
});

const nav = [
  { title: 'Simple Objection', url: '/', icon: <Home /> },
  { title: 'Objection Maker', url: '/maker', icon: <VideoCall /> },
  // { title: 'Courtroom', url: '/courtroom', icon: <Gavel /> },
  { title: 'Admin', url: '/admin', icon: <AdminPanelSettings />, admin: true },
];

const useAccountNav = () => {
  const { loggedIn, logout } = useAuth();

  const accountNav = useMemo(
    () => [
      loggedIn
        ? [
            {
              title: 'My Cases',
              onClick: async () => {
                setMyObjectionsDialogType('case');
                rootStore.dialogs.myObjections = true;
              },
              icon: <MovieFilter />,
            },
            {
              title: 'My Scenes',
              onClick: async () => {
                setMyObjectionsDialogType('scene');
                rootStore.dialogs.myObjections = true;
              },
              icon: <Videocam />,
            },
            {
              title: 'My Assets',
              onClick: loadUserAssets,
              icon: <InsertDriveFile />,
            },
            {
              title: 'My Saved Games',
              onClick: async () => {},
              icon: <Save />,
            },
          ]
        : [],
      loggedIn
        ? [
            {
              title: 'Account',
              onClick: () => {
                rootStore.dialogs.account = true;
              },
              icon: <Person />,
            },
            {
              title: 'Logout',
              onClick: async () => {
                await logout();
              },
              icon: <Logout />,
            },
          ]
        : [
            {
              title: 'Login',
              onClick: () => {
                rootStore.dialogs.login = true;
              },
              icon: <AccountCircle />,
            },
          ],
    ],
    [loggedIn, logout],
  );

  return accountNav;
};

const AppBar = () => {
  const handleOpenLoginDialog = () => {
    rootStore.dialogs.login = true;
  };

  const handleOpenMobileDrawer = (event: React.MouseEvent<HTMLElement>) => {
    state.openMobileDrawer = true;
  };

  const { loggedIn, user } = useAuth();
  const { theme } = useRootStore();

  return (
    <>
      <MuiAppBar
        position="static"
        elevation={theme === 'dark' ? 1 : 0}
        enableColorOnDark
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 300,
                textDecoration: 'none',
              }}
            >
              objection.lol
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="site menu"
                aria-haspopup="true"
                onClick={handleOpenMobileDrawer}
                sx={{ ml: -1 }}
              >
                <MenuIcon sx={{ color: 'primary.contrastText' }} />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {nav
                .filter((page) => !page.admin || (user && user.admin))
                .map((page) => (
                  <AppBarButton
                    key={page.url}
                    component={Link}
                    to={page.url}
                    sx={(theme) => ({
                      my: 2,
                      mr: 1,
                      display: 'block',
                      color: theme.palette.common.white,
                    })}
                  >
                    {page.title}
                  </AppBarButton>
                ))}
            </Box>

            <Stack direction="row" spacing={4}>
              <Stack
                direction="row"
                spacing={1.5}
                display={{ xs: 'none', md: 'block' }}
              >
                <ThemeToggleButton />
              </Stack>

              {loggedIn ? (
                <Box sx={{ flexGrow: 0 }}>
                  <LoggedInUserMenu />
                </Box>
              ) : (
                <Button
                  onClick={handleOpenLoginDialog}
                  sx={(theme) => ({ my: 0, color: theme.palette.common.white })}
                  startIcon={<AccountCircle />}
                >
                  Login
                </Button>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </MuiAppBar>

      <MobileDrawer />
    </>
  );
};

const LoggedInUserMenu = () => {
  const { user } = useAuth();
  const accountNav = useAccountNav();

  const {
    anchorEl: anchorElUser,
    toggle: handleToggleUserMenu,
    close: handleCloseUserMenu,
  } = useAnchorMenu();

  return (
    <>
      <Tooltip title="Account">
        <IconButton onClick={handleToggleUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={user?.name ?? 'Account'}
            src={user?.image ?? undefined}
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              bgcolor: 'inherit',
            })}
          />
        </IconButton>
      </Tooltip>

      <MenuList
        onClick={handleCloseUserMenu}
        anchorEl={anchorElUser}
        open={!!anchorElUser}
        onClose={handleCloseUserMenu}
        placement="bottom-end"
        disabledItemsFocusable
        popperProps={{
          keepMounted: true,
        }}
        paperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {accountNav[0].map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}

        <Divider />

        {accountNav[1].map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </>
  );
};

const MobileDrawer = () => {
  const snapshot = useSnapshot(state);
  const accountNav = useAccountNav();
  const { user } = useAuth();

  const { theme } = useRootStore();
  const { toggleTheme } = rootActions;
  const navigate = useNavigate();

  const handleCloseMobileDrawer = () => {
    state.openMobileDrawer = false;
  };

  return (
    <Drawer
      anchor="left"
      open={snapshot.openMobileDrawer}
      onClose={handleCloseMobileDrawer}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleCloseMobileDrawer}
        onKeyDown={handleCloseMobileDrawer}
      >
        <List>
          {nav
            .filter((page) => !page.admin || (user && user.admin))
            .map(({ title, url, icon }) => (
              <ListItem key={url} disablePadding>
                <ListItemButton onClick={() => navigate(url)}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}

                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>

        {accountNav[0].length > 0 && (
          <>
            <Divider />

            <List>
              {accountNav[0].map(({ title, icon, onClick }) => (
                <ListItem key={title} disablePadding>
                  <ListItemButton onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}

        <Divider />

        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={toggleTheme}>
              <ListItemIcon>
                {theme === 'dark' ? <LightMode /> : <DarkMode />}
              </ListItemIcon>
              <ListItemText>
                {theme === 'dark'
                  ? 'Switch to light Mode'
                  : 'Switch to dark Mode'}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>

        {accountNav[1].length > 0 && (
          <>
            <Divider />

            <List>
              {accountNav[1].map(({ title, icon, onClick }) => (
                <ListItem key={title} disablePadding>
                  <ListItemButton onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </Drawer>
  );
};

const ThemeToggleButton = () => {
  const { theme } = useRootStore();
  const { toggleTheme } = rootActions;

  return (
    <Tooltip title={`Switch to ${theme === 'dark' ? 'light' : 'dark'} mode`}>
      <IconButton onClick={toggleTheme} color="inherit">
        {theme === 'dark' ? <LightMode /> : <DarkMode />}
      </IconButton>
    </Tooltip>
  );
};

const loadUserAssets = async () => {
  rootStore.loading = true;

  try {
    await assetActions.loadUserAssets();

    rootStore.dialogs.assets = true;
  } catch (e) {
    console.error(e);

    enqueueSnackbar('Could not load assets', { variant: 'error' });
  } finally {
    rootStore.loading = false;
  }
};

export default AppBar;
